import React, { Component } from 'react'
import Signin from './components/Signin'
import Signup from './components/Signup'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Dashboard from './components/Dashboard';
import JoinRoom from './components/JoinRoom';
import CreateRoom from './components/CreateRoom';
import ProtectedRoutes from './utils/ProtectedRoutes';
import { CssBaseline } from '@material-ui/core';
import StudentRoom from './components/StudentRoom';
import AdminPage from './components/AdminPage';
import Test from './components/Test'
import Puzzlebank from './components/Puzzlebank';
import Sudoku from './components/Sudoku';

export default class App extends Component {
  render() {
    return (
      <div>
        <CssBaseline />
        <Router>
          <Switch>
            <Route exact path={["/login","/"]}>
              <Signin />
            </Route>
            <ProtectedRoutes path="/dashboard" component={Dashboard} />
            <ProtectedRoutes path="/joinroom" component={JoinRoom} />
            <ProtectedRoutes path="/createroom" component={CreateRoom} />
            <ProtectedRoutes path="/adminpage" component={AdminPage} />
            <Route path="/register">
              <Signup />
            </Route>
            <ProtectedRoutes path="/puzzlebank" component={Puzzlebank} />
            
            <Route path="/test">
              <Sudoku />
            </Route>
          </Switch>
        </Router>
      </div>
    )
  }
}
