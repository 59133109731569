import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Modal,
  Typography,
  withStyles,
  Paper,
  Divider,
  Snackbar,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  IconButton,
  Icon,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import PublishIcon from "@material-ui/icons/Publish";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert } from "@material-ui/lab";
import DoneIcon from "@material-ui/icons/Done";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import axios from "axios";
import React, { Component } from "react";
import { base_url } from "../utils/variables";
import Signup from "../components/Signup";
import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
const styles = (theme) => ({
  containerGrid: {
    // marginLeft: "0",
  },

  card: {
    width: "200px",
    // height:"150px"
  },
  modalInfo: {
    position: "absolute",
    width: 450,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    // border: '2px s',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  registerModalInfo: {
    position: "absolute",
    // width: 450,
    height: "90%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    // border: '2px s',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

class EditRoomAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentList: [],
      modalOpen: false,
      modalData: {},
      roomData: this.props.roomdata,
      sucessMessage: false,
      message: "",
      isLoading: true,
      selectedFile: "",
      uploading: false,
      errmessage: "",
      ErrorMessage: false,
      registerStudent: false,
      confirmDelete: false,
      deleteStudent: "",
      modalType: "add",
      editInfo:''
    };

    this.getStudents = this.getStudents.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.addToRoom = this.addToRoom.bind(this);
    this.removeFromRoom = this.removeFromRoom.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }
  componentDidMount() {
    this.getStudents();
  }

  compare = (a, b) => {
    if (a.isAllowedinRoom < b.isAllowedinRoom) {
      return 1;
    }
    if (a.isAllowedinRoom > b.isAllowedinRoom) {
      return -1;
    }
    return 0;
  };

  getStudents() {
    this.setState({ isLoading: true });
    axios
      .get(`${base_url}getstudentlist?roomid=${this.state.roomData.roomid}`)
      .then(({ data }) => {
        console.log(data);
        data.sort(this.compare);
        this.setState({
          studentList: data,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  }
  openModal(data, event) {
    event.preventDefault();
    this.setState({
      modalOpen: !this.state.modalOpen,
      modalData: data,
    });
  }

  addToRoom(username) {
    axios
      .post(`${base_url}addstudenttoclass`, {
        studentId: username,
        roomid: this.state.roomData.roomid,
      })
      .then((data) => {
        console.log(data);
        this.setState({
          modalOpen: false,
        });
        this.getStudents();
        this.setState({
          sucessMessage: true,
          message: "Student Added to Room",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  removeFromRoom(studentId) {
    console.log(studentId);
    axios
      .post(`${base_url}removestudentfromclass`, {
        studentId: studentId,
        roomid: this.state.roomData.roomid,
      })
      .then((data) => {
        console.log(data);
        this.setState({
          modalOpen: false,
        });
        this.getStudents();
        this.props.terminateStudent(studentId)
        this.setState({
          sucessMessage: true,
          message: "Student Removed From Room",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleFileChange(e) {
    e.preventDefault();
    this.setState({
      selectedFile: e.target.files,
    });
  }

  handleFileUpload(e) {
    e.preventDefault();
    this.setState({
      uploading: true,
    });
    let formdata = new FormData();
    formdata.append("file", this.state.selectedFile[0]);
    console.log(formdata.get("file"));
    axios
      .post(`${base_url}csvupload`, formdata, {
        headers: { "content-type": undefined },
      })
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          this.getStudents();
          this.setState({
            uploading: false,
            selectedFile: "",
            sucessMessage: true,
            message: "Students Added Successfully.",
          });
        } else {
          this.getStudents();
          this.setState({
            ErrorMessage: true,
            errmessage: data.error,
            uploading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          uploading: false,
        });
      });
  }

  handleRegister = (data) => {
    console.log(data);
    if (data.error) {
      this.setState({
        ErrorMessage: true,
        errmessage: "This Student Already Exists.",
      });
    } else {
      this.getStudents();
      this.setState({
        sucessMessage: true,
        message: "New Student Added.",
        registerStudent: false,
      });
    }
  };

  closeRegisterStudent = () => {
    this.getStudents();
    this.setState({
      registerStudent: false,
    });
  };

  deleteStudent = (studentid) => {
    this.setState({
      confirmDelete: true,
      deleteStudent: studentid,
    });
  };

  handleConfirmDelete = () => {
    axios
      .delete(`${base_url}deletestudent?studentid=${this.state.deleteStudent}`)
      .then(({ data }) => {
        console.log(data);
        console.log("Delete success");
        this.props.terminateStudent(this.state.deleteStudent);
        this.handleCloseConfirm();
        this.getStudents();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  editStudent = (index) => {
    let student = this.state.studentList[index];
    this.setState({
      registerStudent: true,
      modalType: "edit",
      editInfo: student
    });
  };

  handleCloseConfirm = (e) => {
    this.setState({
      confirmDelete: false,
      deleteStudent: "",
    });
  };

  render() {
    const { classes } = this.props;
    console.log(this.state.selectedFile);
    return (
      <Box className={this.props.className}>
        <Snackbar
          open={this.state.sucessMessage}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({ sucessMessage: false });
          }}
        >
          <Alert severity="success">{this.state.message}</Alert>
        </Snackbar>
        <Snackbar
          open={this.state.ErrorMessage}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({ ErrorMessage: false });
          }}
        >
          <Alert severity="error">{this.state.errmessage}</Alert>
        </Snackbar>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            marginBottom: "10px",
            alignItems: "center",
          }}
        >
          <Typography style={{ marginRight: "10px" }} variant="button">
            Upload Spreadsheet File :
          </Typography>
          <input
            accept=".csv"
            className={classes.input}
            id="contained-button-file"
            type="file"
            style={{ display: "none" }}
            onChange={this.handleFileChange}
          />

          {!(this.state.selectedFile.length > 0) && (
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                endIcon={<PublishIcon />}
                startIcon={<InsertDriveFileIcon />}
                size="small"
              ></Button>
            </label>
          )}
          {this.state.selectedFile.length > 0 && (
            <Box style={{ marginLeft: "10px", padding: "3px" }}>
              {this.state.selectedFile[0].name}
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    selectedFile: "",
                  });
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
              {!this.state.uploading ? (
                <Tooltip title="Upload">
                  <IconButton onClick={this.handleFileUpload}>
                    <DoneIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <CircularProgress
                  style={{ color: "#737373", alignSelf: "center" }}
                  size={30}
                />
              )}
            </Box>
          )}
        </Box>
        <Button
          style={{ marginBottom: "20px" }}
          endIcon={<AddBoxIcon />}
          onClick={(e) => {
            e.preventDefault();
            this.setState({
              registerStudent: true,
              modalType: "add",
            });
          }}
        >
          Add Individual
        </Button>
        {this.state.isLoading && (
          <CircularProgress style={{ marginLeft: "50%" }} />
        )}

        {!this.state.isLoading && (
          <Grid
            className={classes.containerGrid}
            container
            spacing={3}
            style={{ maxWidth: "100%" }}
          >
            <TableContainer style={{ maxWidth: "100%" }}>
              <Table>
                <colgroup>
                  <col width="20%" />
                  <col width="25%" />
                  <col width="15%" />
                  <col width="10%" />
                  <col width="10%" />
                  <col width="25%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>School</TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.studentList.map((data, index) => {
                    return (
                      <TableRow key={data._id}>
                        <TableCell>{data.name}</TableCell>
                        <TableCell>{data.school}</TableCell>
                        <TableCell>{data.id}</TableCell>
                        <TableCell>{data.contact}</TableCell>
                        <TableCell>
                          {data.isAllowedinRoom ? (
                            <Chip
                              label="In Room"
                              style={{ background: "#03fc84" }}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="More Info">
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                this.openModal(data, e);
                              }}
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>

                          {data.isAllowedinRoom ? (
                            <Tooltip title="Remove From Room">
                              <IconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.removeFromRoom(data.username);
                                }}
                              >
                                <RemoveIcon style={{ fill: "red" }} />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Add to Room">
                              <IconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.addToRoom(data.username);
                                }}
                              >
                                <AddBoxIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Permanantly delete Student">
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                this.deleteStudent(data.username);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit Student Info">
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                this.editStudent(index);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        <Modal open={this.state.modalOpen} onClose={this.toggleModal}>
          <Paper className={classes.modalInfo}>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
              }}
            >
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="h6">Student Details</Typography>
                {!this.state.modalData.isAllowedinRoom ? (
                  <Button
                    style={{
                      marginLeft: "auto",
                      backgroundColor: "#03fc84",
                      marginBottom: "10px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.addToRoom(this.state.modalData.username);
                    }}
                  >
                    Add
                  </Button>
                ) : (
                  <Button
                    style={{
                      marginLeft: "auto",
                      backgroundColor: "#f54842",
                      marginBottom: "10px",
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.removeFromRoom(this.state.modalData.username);
                    }}
                  >
                    Remove
                  </Button>
                )}
              </Box>
              <Divider />
              <Box style={{ marginTop: "10px" }}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="body1" component="div">
                    <Box fontWeight="fontWeightBold" component="div">
                      Name:
                    </Box>
                  </Typography>
                  <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                    {this.state.modalData.name}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="body1" component="div">
                    <Box fontWeight="fontWeightBold" component="div">
                      Email:
                    </Box>
                  </Typography>
                  <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                    {this.state.modalData.email}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="body1" component="div">
                    <Box fontWeight="fontWeightBold" component="div">
                      National Id/ Passport No.:
                    </Box>
                  </Typography>
                  <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                    {this.state.modalData.id}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="body1" component="div">
                    <Box fontWeight="fontWeightBold" component="div">
                      School:
                    </Box>
                  </Typography>
                  <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                    {this.state.modalData.school}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="body1" component="div">
                    <Box fontWeight="fontWeightBold" component="div">
                      Contact:
                    </Box>
                  </Typography>
                  <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                    {this.state.modalData.contact}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="body1" component="div">
                    <Box fontWeight="fontWeightBold" component="div">
                      Parent Name:
                    </Box>
                  </Typography>
                  <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                    {this.state.modalData.parentName}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="body1" component="div">
                    <Box fontWeight="fontWeightBold" component="div">
                      Parent Contact:
                    </Box>
                  </Typography>
                  <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                    {this.state.modalData.parentContact}
                  </Typography>
                </Box>
              </Box>
              <Box style={{ marginTop: "20px", bottom: "0" }}>
                <Divider />
                <Button style={{ float: "right" }} onClick={this.toggleModal}>
                  Close
                </Button>
              </Box>
            </Box>
          </Paper>
        </Modal>
        <Modal
          open={this.state.registerStudent}
          onClose={this.closeRegisterStudent}
        >
          <Paper className={classes.registerModalInfo}>
            <Signup handleRegister={this.handleRegister} edit={(this.state.modalType === "edit") ? true : false} studentInfo={this.state.editInfo} />
          </Paper>
        </Modal>

        <Dialog
          open={this.state.confirmDelete}
          onClose={this.handleCloseConfirm}
        >
          <DialogTitle id="alert-dialog-title">{"Delete student?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this student Permanantly
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleConfirmDelete}>
              Yes
            </Button>
            <Button onClick={this.handleCloseConfirm} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default withStyles(styles)(EditRoomAccess);
