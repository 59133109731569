import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import React, { Component } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
export default class RenderSudoku extends Component {
  constructor(props) {
    super(props);
      let hintVal = []
      for(let i=0; i<81; i++){
        // let inside = []
        hintVal.push({pos: i, pos_i: 1, display: false})
        hintVal.push({pos: i, pos_i: 2, display: false})
        hintVal.push({pos: i, pos_i: 3, display: false})
        hintVal.push({pos: i, pos_i: 4, display: false})
        hintVal.push({pos: i, pos_i: 5, display: false})
        hintVal.push({pos: i, pos_i: 6, display: false})
        hintVal.push({pos: i, pos_i: 7, display: false})
        hintVal.push({pos: i, pos_i: 8, display: false})
        hintVal.push({pos: i, pos_i: 9, display: false})
        // hintVal.push(inside)
      }
    this.state = {
      values: this.props.values,
      solution: [],
      student: this.props.student,
      submit: false,
      activeCell: "",
      hint:false,
      hintVal: hintVal,
      alreadySubmitted: false
    };
  }

  handleSolution = (p, e) => {
    let temp = this.state.solution;
    let insert = false;
    temp.forEach((el, i) => {
      if (el.pos == p) {
        if (e.target.value <= 9) {
          el.val = e.target.value;
        }
        if (el.val == "") {
          temp.splice(i, 1);
        }
        insert = true;
      }
    });
    if (!insert) {
      if (e.target.value <= 9) {
        temp.push({ pos: p, val: e.target.value });
      }
    }

    this.setState({
      solution: temp,
    });
  };

  handleSolutionNew = (e) => {
    let p = this.state.activeCell;
    console.log(p)
    if (p!== "" && !this.state.hint) {
      console.log("OKAY")
      let temp = this.state.solution;
      let insert = false;
      temp.forEach((el, i) => {
        if (el.pos == p) {
          if (e <= 9) {
            el.val = e;
          }
          if (el.val == "") {
            temp.splice(i, 1);
          }
          insert = true;
        }
      });
      if (!insert) {
        if (e <= 9) {
          temp.push({ pos: p, val: e });
        }
      }
      let hintVal = this.state.hintVal
      let ind = hintVal.findIndex((el) => el.pos == p && el.pos_i == 1)
      // console.log("IND", ind,p, hintVal)
      hintVal[ind].display = false
      hintVal[ind+1].display = false
      hintVal[ind+2].display = false
      hintVal[ind+3].display = false
      hintVal[ind+4].display = false
      hintVal[ind+5].display = false
      hintVal[ind+6].display = false
      hintVal[ind+7].display = false
      hintVal[ind+8].display = false
      this.setState({
        solution: temp,
      });
    }

    else if(this.state.hint){
      let hintVal = this.state.hintVal
      // let disp = hintVal.find((el) => el.pos == p && el.pos_i == e).display
      let temp = this.state.solution;
      temp.forEach((el, i) => {
        if (el.pos == p) {
            temp.splice(i, 1);
        }
      });
      let ind = hintVal.findIndex((el) => el.pos == p && el.pos_i == e)
      hintVal[ind].display = !hintVal[ind].display

      this.setState({
        solution:temp,
        hintVal: hintVal
      })
    }
  };

  handleClear = (e) => {
    e.preventDefault();
    this.setState({
      solution: [],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submit: true,
    });
    this.props.scorehandler(this.state.solution);
  };

  handleActiveCell = (pos) => {
    this.setState({
      activeCell: pos,
    });
  };

  componentDidUpdate(){
    if(this.props.submit && !this.state.alreadySubmitted){
      this.setState({checkSolution: true, alreadySubmitted: true, submit:true})
      this.props.scorehandler(this.state.solution);
    }
  }

  render() {
    console.log(this.state.values);
    let rows = [];
    for (let y = 0; y < 9; y++) {
      let cells = [];
      for (let x = 0; x < 9; x++) {
        let pos = y * 9 + x;
        let cellStyle = {
          width: "30px",
          height: "30px",
          border: "1px solid gray",
          lineHeight: "27px",
          textAlign: "center",
          fontSize: "22px",
        };

        if ((pos + 1) % 3 == 0) {
          cellStyle = {
            width: "31px",
            // height: "30px",
            borderRight: "3px solid black",
            borderTop: "1px solid gray",
            borderBottom: "1px solid gray",
            borderLeft: "1px solid gray",
          };
        }
        if (x == 0) {
          cellStyle.width = "31px";
          // cellStyle.height= "30px"
          cellStyle.borderLeft = "3px solid black";
        }
        if (y % 3 == 0) {
          // cellStyle.width = "30px"
          cellStyle.height = "31px";
          cellStyle.borderTop = "3px solid black";
        }
        if (y == 8) {
          // cellStyle.width = "30px"
          cellStyle.height = "31px";
          cellStyle.borderBottom = "3px solid black";
        }

        let celldisable = false;
        // let inputStyle = {
        //   height: "95%",
        //   width: "95%",
        //   border: "0",
        //   textAlign: "center",
        //   fontSize: "1.2em",
        // }
        let cellValue = this.state.values.find((el) => el.pos == pos);
        if (cellValue) {
          cellStyle.backgroundColor = "rgb(173,216,230)";
          cellStyle.fontWeight = "600";
          celldisable = true;
        }
        if (this.state.submit) {
          celldisable = true;
        }
        let inputCellStyle = {
          position: "absolute",
          top: "0",
          left: "0",
          width: "30px",
          height: "30px",
          lineHeight: "27px",
          textAlign: "center",
          fontSize: "22px",
        };

        // console.log("Correct: ", this.props.correctSolution)
        if (this.state.student) {
          let soln = this.props.correctSolution.find((el) => el.pos == pos);
          if (soln && this.state.submit) {
            inputCellStyle.backgroundColor = "#EB3941";
            inputCellStyle.color = "white";
            let ans = this.state.solution.find((el) => el.pos == pos);
            if (ans) {
              if (ans.val == soln.val) {
                inputCellStyle.backgroundColor = "#059B00";
              }
            }
          }
        }

        if (!cellValue) {
          cellValue = this.state.solution
            ? this.state.solution.find((el) => el.pos == pos)
            : "";
        }
        if (this.props.teacher && !cellValue) {
          cellValue = this.props.correctSolution.find((el) => el.pos == pos);
        }

        cellStyle.position = "relative";

        if (pos === this.state.activeCell) {
          inputCellStyle.border = this.state.hint ? "2px dashed orange" :"2px solid #0a6ebd";
        }
        
        cells.push(
          <td style={cellStyle} key={pos}>
            {/* <input
              disabled={celldisable || this.props.view}
              style={inputStyle}
              value={cellValue ? cellValue.val : ""}
              onChange={(e) => this.handleSolution(pos, e)}
            ></input> */}
            {this.state.student && !celldisable ? (
              <div
                style={inputCellStyle}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleActiveCell(pos);
                }}
              >
                {cellValue ? cellValue.val : ""}
              </div>
            ) : (
              <div style={inputCellStyle}>{cellValue ? cellValue.val : ""}</div>
            )}
            
            {!(celldisable) && this.state.student
            &&
            <div style={{position:"absolute", top:"0", left:"0", width:"28px", height:"28px", zIndex:"-100"}}>

              {this.state.hintVal.find((el) => el.pos == pos && el.pos_i == 1).display &&
                <div style={{position: "absolute", top:"0", left:"0", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>1</div>}
              {this.state.hintVal.find((el) => el.pos == pos && el.pos_i == 2).display &&<div style={{position: "absolute", top:"0", left:"9px", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>2</div>}
              {this.state.hintVal.find((el) => el.pos == pos && el.pos_i == 3).display && <div style={{position: "absolute", top:"0", left:"18px", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>3</div>}
              {this.state.hintVal.find((el) => el.pos == pos && el.pos_i == 4).display && <div style={{position: "absolute", top:"9px", left:"0", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>4</div>}
              {this.state.hintVal.find((el) => el.pos == pos && el.pos_i == 5).display && <div style={{position: "absolute", top:"9px", left:"9px", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>5</div>}
              {this.state.hintVal.find((el) => el.pos == pos && el.pos_i == 6).display && <div style={{position: "absolute", top:"9px", left:"18px", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>6</div>}
              {this.state.hintVal.find((el) => el.pos == pos && el.pos_i == 7).display && <div style={{position: "absolute", top:"18px", left:"0", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>7</div>}
              {this.state.hintVal.find((el) => el.pos == pos && el.pos_i == 8).display && <div style={{position: "absolute", top:"18px", left:"9px", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>8</div>}
              {this.state.hintVal.find((el) => el.pos == pos && el.pos_i == 9).display && <div style={{position: "absolute", top:"18px", left:"18px", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>9</div>        }
            </div>
            }
          </td>
        );
      }
      rows.push(<tr key={y}>{cells}</tr>);
    }

    return (
      <div
        style={{ display: "flex", flexDirection: "row", userSelect: "none" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            flexDirection: "column",
            width: "280px",
          }}
        >
          {this.state.student && !this.state.submit &&
          <Tooltip title={this.state.hint ? "Write Solution": "Write Hint"}>
            <IconButton onClick={(e) => {e.preventDefault(); this.setState({hint: !this.state.hint})}}>
              <CreateIcon />
            </IconButton>
          </Tooltip>}
          <table
            cellPadding={0}
            cellSpacing={0}
            style={{ alignSelf: "center" }}
          >
            <tbody>{rows}</tbody>
          </table>
          {!this.props.view && !this.state.submit && (
            <Tooltip title="Clear Puzzle">
              <IconButton onClick={this.handleClear}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          )}
          {this.state.student && !this.state.submit && (
            <Button onClick={this.handleSubmit}> Submit </Button>
          )}
        </div>
        {this.state.student && !this.state.submit && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "63px",
              // justifyContent:"center",
              alignItems: "center",
              marginLeft: "5px",
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((val) => {
              return (
                <div
                  style={{
                    height: "30px",
                    width: "30px",
                    border: "1px solid black",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "5px",
                    marginBottom: "2px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleSolutionNew(val);
                  }}
                >
                  {val}
                </div>
              );
            })}
          </Box>
        )}
      </div>
    );
  }
}
