import axios from 'axios';
import { base_url } from './variables';

export const authenticateToken = async() => {
    let token = localStorage.getItem('token')
    console.log(token)
    if(!token){
        return {valid:false}
    }
    try{
        const data = await axios.post(`${base_url}validatetoken`,{},{
            headers: {
                authorization: `BearerToken ${token}`
            }
        })
        if(data){
            return { data:data.data, valid: true}
        }
    }
    catch(err){
        
        return { valid:false}
    }
    
}