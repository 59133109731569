import {
  Box,
  Grid,
  withStyles,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Tooltip,
  Modal,
  CircularProgress,
  Paper,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import React, { Component } from "react";
import Appbar from "./Appbar";
import axios from "axios";
import { base_url } from "../utils/variables";
import EditIcon from "@material-ui/icons/Edit";
const styles = (theme) => ({
  Container: {
    display: "flex",
  },
  Card: {
    width: "250px",
    backgroundColor: "#F5F5F5",
  },
  cardStudent: {
    width: "400px",
    marginTop: "20px",
    backgroundColor: "#F5F5F5",
  },
  containerGrid: {
    marginTop: "20px",
  },
  addNew: {
    marginTop: "20px",
  },
  modalInfo: {
    position: "absolute",
    width: 450,
    height: 360,
    backgroundColor: theme.palette.background.paper,
    // border: '2px s',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teachers: [],
      modalOpen: false,
      username: "",
      name: "",
      password: "",
      disableConfirm: true,
      usernameError: false,
      isLoading: false,
      sucessMessage: false,
      message: "",
      isConfirming: false,
      modalType: "add",
      oldUsername: "",
    };

    this.getTeachers = this.getTeachers.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.checkConfirmDisable = this.checkConfirmDisable.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  getTeachers() {
    this.setState({
      isLoading: true,
    });
    axios.get(`${base_url}listteachers`).then(({ data }) => {
      this.setState({
        teachers: data,
        isLoading: false,
      });
    });
  }

  toggleModal() {
    this.checkConfirmDisable()
    this.setState({
      modalType: "add",
      modalOpen: !this.state.modalOpen,
    });
  }

  componentDidMount() {
    this.getTeachers();
  }

  handleCancel() {
    this.toggleModal();
    this.setState({
      name: "",
      username: "",
      password: "",
      usernameError: false,
    });
  }

  checkConfirmDisable() {
    if (this.state.name && this.state.username && this.state.password) {
      this.setState({
        disableConfirm: false,
      });
    }
    else {
      this.setState({
        disableConfirm: true
      })
    }
  }

  handleConfirm() {
    this.setState({ isConfirming: true });
    if (this.state.modalType === "add") {
      axios
        .post(`${base_url}createuser`, {
          username: this.state.username,
          password: this.state.password,
          role: "teacher",
          name: this.state.name,
        })
        .then(({ data }) => {
          console.log(data);
          if (data.error) {
            this.setState({
              usernameError: true,
              isConfirming: false,
            });
          } else {
            this.handleCancel();
            this.setState({
              sucessMessage: true,
              message: "Teacher Added Sucessfully",
              isConfirming: false,
            });
            this.getTeachers();
          }
        });
    }
    if(this.state.modalType === "edit"){
      this.confirmEditData()
    }
    
  }

  handleDelete(id) {
    this.setState({
      isDeleting: true,
    });
    axios.delete(`${base_url}deleteteacher?userid=${id}`).then(({ data }) => {
      this.setState({
        isDeleting: false,
      });
      this.getTeachers();
    });
  }

  handleEditData = (index) => {
    // console.log(this.state.teachers[index])
    this.checkConfirmDisable()
    const teacher = this.state.teachers[index];
    this.setState({
      modalType: "edit",
      modalOpen: true,
      username: teacher.username,
      name: teacher.name,
      oldUsername: teacher.username,
    });
  };

  confirmEditData = () => {
    axios
      .post(`${base_url}edituser`, {
        oldUsername: this.state.oldUsername,
        username: this.state.username,
        password: this.state.password,
        role: "teacher",
        name: this.state.name,
      })
      .then(({ data }) => {
        console.log(data);
        this.getTeachers()
        this.setState({
          isConfirming: false,
          modalOpen: false,
          sucessMessage: true,
          message: "Teacher Edited Sucessfully",
        })
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isConfirming: false,
          modalOpen: false
        })
      });
  };

  render() {
    let { classes } = this.props;
    return (
      <Box>
        <Appbar
          view="Manage Teachers"
          role={this.props.userData.role}
          inManage={true}
        />
        <Snackbar
          open={this.state.sucessMessage}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({ sucessMessage: false });
          }}
        >
          <Alert severity="success">{this.state.message}</Alert>
        </Snackbar>
        <Box style={{ marginLeft: "20px" }}>
          <Tooltip title="Add New Teacher">
            <Button
              className={classes.addNew}
              endIcon={<AddToPhotosIcon style={{ color: "#0B2544" }} />}
              onClick={(e) => {
                e.preventDefault();
                this.toggleModal();
              }}
            >
              Add
            </Button>
          </Tooltip>
          {
            <Grid
              className={classes.containerGrid}
              container
              spacing={3}
              style={{ maxWidth: "100%" }}
            >
              {this.state.isLoading && <CircularProgress />}
              {this.state.teachers.map((data, index) => {
                return (
                  <Grid item key={data._id} lg={2} md={3} xs={4} sm={5}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                          <Typography
                            variant="body1"
                            fontWeight={200}
                            component="div"
                          >
                            <Box fontWeight="fontWeightBold" component="div">
                              Name:
                            </Box>
                          </Typography>
                          <Typography
                            vaiant="body2"
                            style={{ marginLeft: "10px" }}
                          >
                            {data.name}
                          </Typography>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                          <Typography variant="body1" component="div">
                            <Box fontWeight="fontWeightBold" component="div">
                              Username:
                            </Box>
                          </Typography>
                          <Typography
                            vaiant="body2"
                            style={{ marginLeft: "10px" }}
                          >
                            {data.username}
                          </Typography>
                        </Box>
                      </CardContent>
                      <CardActions>
                        <Button
                          endIcon={
                            <DeleteForeverIcon style={{ color: "red" }} />
                          }
                          size="small"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleDelete(data.username);
                          }}
                        >
                          <Typography variant="body2">Delete</Typography>
                        </Button>
                        <Button
                          endIcon={<EditIcon />}
                          size="small"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleEditData(index);
                          }}
                        >
                          <Typography variant="body2">Edit</Typography>
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          }
        </Box>
        <Modal open={this.state.modalOpen} onClose={this.toggleModal}>
          <Paper className={classes.modalInfo}>
            <Typography variant="h6">
              {this.state.modalType === "add"
                ? "Set Teacher Credentials"
                : "Edit Teacher Credentials"}
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
              }}
            >
              {this.state.modalType === "edit" && (
                <Typography>Editing {this.state.username}</Typography>
              )}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                value={this.state.name}
                onChange={(e) => {
                  this.setState({ name: e.target.value }, () => {
                    this.checkConfirmDisable();
                  });
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                value={this.state.username}
                onChange={(e) => {
                  this.setState({ username: e.target.value }, () => {
                    this.checkConfirmDisable();
                  });
                }}
                error={this.state.usernameError}
                helperText={
                  this.state.usernameError ? "Username Already Taken" : null
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label={(this.state.modalType==="add") ? "password" : "set new password"}
                name="password"
                value={this.state.password}
                onChange={(e) => {
                  this.setState({ password: e.target.value }, () => {
                    this.checkConfirmDisable();
                  });
                }}
                // error={usernameError}
                // helperText={(usernameError) ? "Username Already Taken" : null}
              />
            </Box>
            <Typography
              variant="caption"
              gutterBottom
              style={{ marginBottom: "10px" }}
            >
              Remember the username and password{" "}
            </Typography>
            <Button style={{ float: "right" }} onClick={this.handleCancel}>
              Cancel
            </Button>
            {!this.state.isConfirming ? (
              <Button
                style={{ float: "right" }}
                disabled={this.state.disableConfirm}
                onClick={this.handleConfirm}
              >
                Confirm
              </Button>
            ) : (
              <Button style={{ float: "right" }} disabled>
                <CircularProgress />
              </Button>
            )}
          </Paper>
        </Modal>
      </Box>
    );
  }
}

export default withStyles(styles)(AdminPage);
