import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { React, useState } from "react";
import AppsIcon from "@material-ui/icons/Apps";
import { Link, Redirect } from "react-router-dom";
import logo from "../utils/logo2.png";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: "0.5em",
  },
  appbar: {
    backgroundColor:"#8300E9",
    "& .MuiButtonBase-root": {
      color: "White",
      backgroundColor: "#0B2544",
      boxShadow: "0 3px 5px 2px rgba(11,37,68,0.3)",
      marginLeft: "10px",
      marginRight: "10px",
      float: "right",
    },
    width:"100%"
  },
  nav: {
    right: "0px",
    width: "500px",
    position: "absolute",
  },
  logout: {
    right: "0px",
  },
  logo:{
    width:"150px",
    height:"64px",
    display:"block",
    margin : "auto",
    borderRadius:"5px",
    left:"50%",
    marginLeft:"-75px",
    position:"absolute"
  }
}));

const Appbar = (props) => {
  const classes = useStyles();
  const [logout, setLogout] = useState(false);
  const handleLogout = () => {
    localStorage.clear();
    setLogout(true)
  };
  console.log(props);

  const camelize = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    }).replace(/\s+/g, '');
  }
  return (
    <div>
      {logout && <Redirect to="/login" />}
      <AppBar position="sticky" className={classes.appbar}>
        <Toolbar>
          <a href="/dashboard" style={{textDecoration:"none"}}>
          <AppsIcon className={classes.icon} style={{color:"white"}} />
          </a>
          <Typography variant="h6" noWrap>
          {camelize(props.role)}{"  "}{props.view}
          </Typography>
          {!(props.view === 'Dashboard') && <img src={logo} className={classes.logo}/>}
          <Grid className={classes.nav}>
            <Button className={classes.logout} onClick={handleLogout}>
              Logout
            </Button>
            {props.role === "teacher" && (!props.inCreateRoom) && <Button component={Link} to="/createroom">Create Room</Button>}
            {props.role === "teacher" && (!props.inCreateRoom) && <Button component={Link} to="/puzzlebank"> Puzzle Bank</Button>}
            {props.role === "student" && (!props.inRoom) && <Button component={Link} to="/joinroom">Join Room</Button>}
            {(props.inRoom) && <Button onClick={() => {props.handleleaveRoom();window.location.reload()}} >Leave Room</Button>}
            {(props.inCreateRoom) && <Button onClick={() => {window.location.reload()}} >Leave Room</Button>}
            {props.role === 'admin' && (!props.inManage) && <Button component={Link} to="/adminpage">Manage Teachers</Button>}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Appbar;
