import { CircularProgress, Paper, Typography } from '@material-ui/core'
import React from 'react'

const PleaseWait = (props) => {
    return (
        <div className={props.className}>
            <Paper style={{display: "flex", flexDirection:"column", width:"300px", height:"300px", alignItems:"center", justifyContent:"center", padding:"10px"}}>
                <Typography align="center">{(props.role==="student") ? "Please Wait while teacher assigns you a new puzzle" : "Please wait while the students complete the puzzle"}</Typography>
                <CircularProgress style={{marginTop:"10px"}} />
            </Paper>
        </div>
    )
}

export default PleaseWait
