import { React, useEffect, useState } from "react";
import { Redirect } from "react-router";
import { authenticateToken } from "../utils/authenticateToken.js";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar.jsx";
import {
  AppBar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  makeStyles,
  Paper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Appbar from "./Appbar.jsx";
import Axios from "axios";
import { base_url } from "../utils/variables.js";
import logo from '../utils/logo.png';

const useStyles = makeStyles((theme) => ({
  Container: {
    display: "flex",
  },
  Card: {
    width: "250px",
    marginTop: "20px",
    backgroundColor: "#F5F5F5",
  },
  cardStudent :{
    width: "400px",
    marginTop: "20px",
    backgroundColor: "#F5F5F5",
  },
  logo:{
    width:"300px",
    height:"200px",
    marginLeft:"auto",
    marginRight:"auto",
    display:"block"
    // margin : "auto"
  }
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const [studentData, setStudentData] = useState("");
  const getStudentDetails = () => {
    Axios.get(
      `${base_url}getstudentinfo?username=${props.userData.username}`
    ).then(({ data }) => {
      console.log(data[0]);
      setStudentData(data[0]);
    });
  };

  useEffect(() => {
    if (props.userData.role === "student") {
      getStudentDetails();
    }
  }, []);

  console.log(props);
  return (
    <div>
      <Appbar view="Dashboard" role={props.userData.role} />
      <img src={logo} className={classes.logo}/>
      <Box style={{display:"flex", justifyContent:"center"}}>
      <Typography variant="h5" style={{fontWeight:"600"}}>
        Welcome to BRAINWAVES Portal!
      </Typography>
      </Box>
      <Container className={classes.mainBody}>
        {/* <Paper className={classes.imagePaper}>
                </Paper> */}
        {props.userData.role === "teacher" && (
          <Card className={classes.Card}>
            <CardContent>
              <Typography gutterBottom variant="body2" color="textSecondary">
                Your Information{" "}
              </Typography>
              <Typography variant="h6">{props.userData.name}</Typography>
              <Typography gutterBottom variant="body1">
                {props.userData.role}
              </Typography>
            </CardContent>
          </Card>
        )}

        {props.userData.role === "admin" && (
          <Card className={classes.Card}>
            <CardContent>
              <Typography gutterBottom variant="body2" color="textSecondary">
                User Information{" "}
              </Typography>
              <Typography variant="h6">{props.userData.name}</Typography>
              <Typography gutterBottom variant="body1">
                {props.userData.role}
              </Typography>
            </CardContent>
          </Card>
        )}

        {props.userData.role === "student" && (
          <Card className={classes.cardStudent}>
              <CardHeader
                title="Your Details"
              />
            <CardContent>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography variant="body1" component="div">
                  <Box fontWeight="fontWeightBold" component="div">
                    Name:
                  </Box>
                </Typography>
                <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                  {studentData.name}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <Typography variant="body1" component="div">
                  <Box fontWeight="fontWeightBold" component="div">
                    Email:
                  </Box>
                </Typography>
                <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                  {studentData.email}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <Typography variant="body1" component="div">
                  <Box fontWeight="fontWeightBold" component="div">
                    National Id/ Passport No.:
                  </Box>
                </Typography>
                <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                  {studentData.id}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <Typography variant="body1" component="div">
                  <Box fontWeight="fontWeightBold" component="div">
                    School:
                  </Box>
                </Typography>
                <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                  {studentData.school}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <Typography variant="body1" component="div">
                  <Box fontWeight="fontWeightBold" component="div">
                    Contact:
                  </Box>
                </Typography>
                <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                  {studentData.contact}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <Typography variant="body1" component="div">
                  <Box fontWeight="fontWeightBold" component="div">
                    Parent Name:
                  </Box>
                </Typography>
                <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                  {studentData.parentName}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <Typography variant="body1" component="div">
                  <Box fontWeight="fontWeightBold" component="div">
                    Parent Contact:
                  </Box>
                </Typography>
                <Typography vaiant="body2" style={{ marginLeft: "10px" }}>
                  {studentData.parentContact}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default Dashboard;
