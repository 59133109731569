import { CircularProgress } from "@material-ui/core";
import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { authenticateToken } from "./authenticateToken";

export default class ProtectedRoutes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: false,
      isLoading: true,
      userData:''
    };
  }
  

  componentDidMount() {
    authenticateToken()
      .then((data) => {
        console.log(data);
        let newState = {
          authenticated: data.valid,
          isLoading: false
        }
        if(data.valid){
            newState.userData = data.data
        }
        this.setState(newState)
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          authenticated: false,
          isLoading: false,
        });
      });
  }

  render() {
    const { component: Component, ...rest } = this.props;
    console.log("rest", rest)
    const { userData } = this.state
    console.log(userData)
    if (this.state.isLoading) {
      return( 
      <div>
        <CircularProgress />
      </div>
      )
    }

    if (!this.state.authenticated) {
      return <Redirect to="/login" />;
    }

    console.log(this.state)
    return (
      <Route
        {...rest}
        render={(props) => {
            console.log(this.state)
            props.userData = userData
          return <Component {...rest} {...props} />;
        }}
      />
    );
  }
}


// const ProtectedRoutes = ({component: Component, ...rest}) =>{
//   const auth={
//     authenticated: false,
//     userData:'',

//   }
//     authenticateToken()
//       .then((data) => {
//         console.log(data);
//         auth.authenticated = data.valid
//         if(data.valid){
//             auth.userData = data.data
            
//         }
//         return <Route
//         {...rest}
//         render={(props) => {
//             props.userData = auth.userData
//           return <Component {...rest} {...props} />;
//         }}
//       />

//       })
//       .catch((err) => {
//         console.log(err);
//         auth.authenticated = false;
//         return <Redirect to="/login" />;
//       });
// }

// export default ProtectedRoutes
