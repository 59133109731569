import {
  Box,
  Button,
  Divider,
  TableContainer,
  TextField,
  withStyles,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { io } from "socket.io-client";
import React, { Component } from "react";
import Appbar from "./Appbar";
import AddBoxIcon from "@material-ui/icons/AddBox";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import StudentRoom from "./StudentRoom";
import { base_url } from '../utils/variables'
const styles = (theme) => ({
  formcontainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
  },
});

let socket;

class JoinRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomList: [],
      roomId: "",
      error: {
        display: false,
        message: "",
      },
      roomInfo: "",
      isJoined:false,
      loading: false,
      
    };
    this.getRoomList = this.getRoomList.bind(this);

    this.handleRoomIdChange = this.handleRoomIdChange.bind(this);
    this.handleJoinRoom = this.handleJoinRoom.bind(this);
    this.closeError = this.closeError.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);
  }

  componentDidMount() {
    this.getRoomList();
    socket = io(base_url)
  }

  getRoomList() {
    this.setState({
      loading: true
    })
    let config = {
      headers: {
        authorization: `BearerToken ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(`${base_url}listrooms`, config)
      .then((data) => {
        this.setState({
          roomList: data.data,
          loading: false
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleRoomIdChange(e) {
    this.setState({
      roomId: e.target.value,
    });
  }

  handleJoinRoom(roomID,e) {
    e.preventDefault();
    let config = {
      headers: {
        authorization: `BearerToken ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(
        `${base_url}studentjoinroom`,
        { roomid: roomID },
        config
      )
      .then(({ data }) => {
        console.log(data);
        if (data.error) {
          this.setState({
            error: {
              display: true,
              message: data.error,
            },
          });
        } else {
          axios
            .get(
              `${base_url}getroominfo?roomid=${roomID}`,
              config
            )
            .then(({ data }) => {
              this.setState({
                roomInfo: data,
                isJoined:true
              });
            });
        }
      })
      .catch(err => {
        let error = err.response.data
        if(error.error){
          this.setState({
            error:{
              display: true,
              message: error.error
            }
          })
        }
      })
  }

  closeError() {
    this.setState({
      error: {
        display: false,
        message: "",
      },
    });
  }


  leaveRoom(){
    socket.emit('studentleaveroom', ({username: this.props.userData.username, roomid:this.state.roomInfo.roomid}))
    socket.disconnect();
  }


  render() {
    console.log("STATE", this.state);
    const { classes } = this.props;

    return (
      <Box>
        <Snackbar
          open={this.state.error.display}
          autoHideDuration={3000}
          onClose={this.closeError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">{this.state.error.message}</Alert>
        </Snackbar>
        <Appbar view="Join Room" role={this.props.userData.role} inRoom={(this.state.isJoined)} handleleaveRoom={this.leaveRoom}/>

        { !this.state.isJoined &&
        <Box>
          <Box className={classes.formcontainer}>
          <TextField
            id="outlined-basic"
            label="Enter Room Id"
            variant="outlined"
            value={this.state.roomId}
            onChange={this.handleRoomIdChange}
          />
          <Button
            endIcon={<AddBoxIcon />}
            style={{ marginLeft: "10px" }}
            onClick={(e) => {this.handleJoinRoom(this.state.roomId,e)}}
          >
            Join
          </Button>
        </Box>
        <Divider style={{ marginTop: "10px" }} />
        <Box style={{display: "flex", justifyContent:"center"}}>
        {this.state.loading && <CircularProgress />}
        </Box>
           
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sr. No</TableCell>
                <TableCell>Room Name</TableCell>
                <TableCell>RoomId</TableCell>
                <TableCell>Teacher</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.roomList.map((data, index) => (
                <TableRow key={data.roomid}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{data.roomid}</TableCell>
                  <TableCell>{data.roomName}</TableCell>
                  <TableCell>{data.createdBy}</TableCell>
                  <TableCell>
                    <Button
                      endIcon={<AddBoxIcon />}
                      onClick={(e) => {this.handleJoinRoom(data.roomid,e)}}
                    >
                      Join
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
        }
        { this.state.isJoined &&
          <StudentRoom userData={this.props.userData} roomInfo={this.state.roomInfo} leaveroom={this.leaveRoom}/>
        }

      </Box>
    );
  }
}

export default withStyles(styles)(JoinRoom);
