import { Button, IconButton, Tooltip } from "@material-ui/core";
import React, { Component } from "react";
import ClearIcon from '@material-ui/icons/Clear';
export default class Sudoku extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      confirm: false,
      solution: [],
      isSolution: false,
    };
  }

  handleInput = (p, e) => {
    if (!this.state.confirm) {
      e.preventDefault();
      let temp = this.state.values;
      let insert = false;
      temp.forEach((el, i) => {
        if (el.pos == p) {
          if (e.target.value <= 9) {
            el.val = e.target.value;
          }
          if (el.val == "") {
            temp.splice(i, 1);
          }
          insert = true;
        }
      });
      if (!insert) {
        if (e.target.value <= 9) {
          temp.push({ pos: p, val: e.target.value });
        }
      }

      this.setState({
        values: temp,
      });
    } else {
      this.handleSolution(p, e);
    }
  };

  handleSolution = (p, e) => {
    e.preventDefault();
    let temp = this.state.solution;
    let insert = false;
    temp.forEach((el, i) => {
      if (el.pos == p) {
        if (e.target.value <= 9) {
          el.val = e.target.value;
        }
        if (el.val == "") {
          temp.splice(i, 1);
        }
        insert = true;
      }
    });
    if (!insert) {
      if (e.target.value <= 9) {
        temp.push({ pos: p, val: e.target.value });
      }
    }

    this.setState(
      {
        solution: temp,
      },
      () => {
        this.checkConfirm();
      }
    );
  };

  handleConfirm = (e) => {
    e.preventDefault();
    this.setState(
      {
        confirm: !this.state.confirm,
      },
      () => {
        if (!this.state.confirm) {
          this.setState({
            isSolution: false,
            solution: [],
            values: this.state.values.concat(this.state.solution),
          },() => {
            this.props.toggleConfirm(false);
          });
        }
      }
    );
  };

  handleSolutionSelect = (e) => {
    e.preventDefault();
    this.setState({
      isSolution: true,
    });
  };

  checkConfirm = () => {
    if (this.state.solution.concat(this.state.values).length == 81) {
      console.log("Filled", true);
      this.props.toggleConfirm(true);
      this.props.updatePuzConfig({values: this.state.values, solution:this.state.solution})
      return true;
    }
    console.log("Filled", false);
    this.props.updatePuzConfig("")
    this.props.toggleConfirm(false)
    return false;
  };

  handleClear = (e) => {
    e.preventDefault();
    this.setState({
      solution:[],
      values:[]
    }, () => {
      this.checkConfirm()
    })
  }

  render() {
    console.log(this.state.values);
    let rows = [];
    for (let y = 0; y < 9; y++) {
      let cells = [];
      for (let x = 0; x < 9; x++) {
        let pos = y * 9 + x;
        let cellStyle = {
          width: "2.5em",
          height: "2.5em",
          border: "1px solid gray",
        };

        if ((pos + 1) % 3 == 0) {
          cellStyle = {
            width: "2.5em",
            height: "2.5em",
            borderRight: "3px solid black",
            borderTop: "1px solid gray",
            borderBottom: "1px solid gray",
            borderLeft: "1px solid gray",
          };
        }
        if (x == 0) {
          cellStyle.borderLeft = "3px solid black";
        }
        if (y % 3 == 0) {
          cellStyle.borderTop = "3px solid black";
        }
        if (y == 8) {
          cellStyle.borderBottom = "3px solid black";
        }

        let celldisable = false;
        let cellValue = this.state.values.find((el) => el.pos == pos);
        if (this.state.confirm && !this.state.isSolution) {
          celldisable = true;
        }
        if (this.state.confirm && cellValue) {
          cellStyle.backgroundColor = "rgb(173,216,230)";
          celldisable = true;
        }
        if (!cellValue) {
          cellValue = this.state.solution
            ? this.state.solution.find((el) => el.pos == pos)
            : "";
        }
        // if(cell)
        cells.push(
          <td style={cellStyle} key={pos}>
            <input
              disabled={celldisable}
              style={{
                height: "95%",
                width: "95%",
                border: "0",
                textAlign: "center",
                fontSize: "1.2em",
              }}
              value={cellValue ? cellValue.val : ""}
              onChange={(e) => this.handleInput(pos, e)}
            ></input>
          </td>
        );
      }
      rows.push(<tr key={y}>{cells}</tr>);
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          flexDirection: "column",
        }}
      >
        <table cellPadding={0} cellSpacing={0} style={{ alignSelf: "center" }}>
          <tbody>{rows}</tbody>
        </table>
        <Button style={{ marginTop: "5px" }} onClick={this.handleConfirm}>
          {this.state.confirm ? "Edit" : "Confirm"}
        </Button>
        <Tooltip title="Clear Puzzle">
        <IconButton onClick={this.handleClear}>
          <ClearIcon />
        </IconButton>
        </Tooltip>
        {this.state.confirm && !this.state.isSolution && (
          <Button
            style={{ marginTop: "5px" }}
            onClick={this.handleSolutionSelect}
          >
            Add solution
          </Button>
        )}
      </div>
    );
  }
}
