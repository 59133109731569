import React, { Component } from "react";
import PleaseWait from "./PleaseWait";
import { io } from "socket.io-client";
import { Box, Grid, Snackbar, Typography, withStyles } from "@material-ui/core";
import AppCoverStudent from "./AppCoverStudent";
import Timer from "./Timer";
import LeaderBoard from "./LeaderBoard";
import axios from "axios";
import { base_url } from "../utils/variables";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Redirect } from "react-router-dom";
import RenderSudoku from "./RenderSudoku";
const styles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    marginTop: "20px",
    width: "50%",
    alignItems: "center",
    "& .MuiTextField-root": {
      marginLeft: "20px",
      width: "8em",
    },
  },
  leaderdisplay: {
    marginTop: "40px",
    marginLeft: "10px",
  },
  infoContainer: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
  },
  infoChild: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "10px",
  },
});

class StudentRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPuzAssign: false,
      puzConfig: "",
      puzType: "",
      time: 0,
      score: 0,
      dispScore: 0,
      leaderboard: [],
      forceSubmit: false,
      openMessage: false,
      showSolution: false,
      reload: false,
      logout: false,
      disconnectNotification: false,
    };

    this.handlescorechange = this.handlescorechange.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.getMyScore = this.getMyScore.bind(this);
    this.socket = null;
    this.getLeaderboard = this.getLeaderboard.bind(this);
    this.handleCloseMessage = this.handleCloseMessage.bind(this);
    this.retryInterval = 10000;
  }

  componentDidMount() {
    this.socket = io(base_url);

    this.socket.on("connect", () => {
      this.setState({
        disconnectNotification: false,
      });
      this.socket.emit("joinroom", {
        username: this.props.userData.username,
        roomid: this.props.roomInfo.roomid,
      });
    });

    // this.socket.on("connect", () => {
    //   console.log("CONNECTED")
    //   console.log(this.props.roomInfo.roomid)
    // })

    this.socket.on("scoreupdate", () => {
      console.log("order to get score");
      this.getMyScore();
    });

    this.socket.on("newpuzzle", ({ config, puzType, min, sec }) => {
      console.log("TEACHER GAVE ME NEW PUZZLE");
      console.log(config, puzType, min, sec);
      this.setState(
        {
          puzConfig: config,
          puzType: puzType,
          time: this.calculateSeconds(min, sec),
          totalTIme: this.calculateSeconds(min, sec),
          isPuzAssign: true,
          forceSubmit: false,
          showSolution: false,
          reload: true,
        },
        () => {
          this.setState({ reload: false });
        }
      );
      this.startTimer();
    });

    this.socket.on("testevent", () => {
      console.log("this is test event");
    });

    this.socket.onAny((event, ...args) => {
      console.log(`got ${event}`);
    });

    this.socket.on("leaderboardupdate", () => {
      console.log("Getting leader board");
      this.getLeaderboard();
    });

    this.socket.on("info", (data) => {
      if (!data) {
        console.log("Info EVENT");
      }
    });

    this.socket.on("requestsubmit", () => {
      // if(this.state.isPuzAssign){
        this.setState({
          isPuzAssign: false,
          time: 0,
          
        }, () => {
          this.setState({
            showSolution: true,
          forceSubmit: true,
          })
        });
      // }
    });

    this.socket.on("disconnect", (reason) => {
      console.log(reason);
      console.log("You have been disconnected from internet.");
      console.log("retrying ...");
      this.setState({
        disconnectNotification: true,
      });
      // this.socket.connect()
    });
    this.socket.on("terminatesession", ({username}) => {
      if(username){
        if(username == this.props.userData.username){
          this.handleLogout();
        }
      }
      else{
        this.handleLogout();
      } 
    });
  }

  handleRetryConnect() {}

  componentWillUnmount() {
    this.socket.emit("studentleaveroom", {
      username: this.props.userData.username,
      roomid: this.props.roomInfo.roomid,
    });
    this.socket.disconnect();
  }

  handlescorechange(puzCells, input) {
    let newscore = 0;
    puzCells.forEach((val, index) => {
      if (Number(input[index]) === Number(val)) {
        newscore += 1;
      } else if (input[index] == "") {
        newscore += 0;
      } else if (Number(input[index]) !== Number(val)) {
        newscore -= 1;
      }
    });

    if (newscore < 0) {
      newscore = 0;
    }

    console.log(newscore);
    let timeleft = this.state.time;
    let allotedTime = this.state.totalTIme;
    let timetaken = allotedTime - timeleft;
    let finalScore = newscore;

    this.setState({
      score: finalScore,
      isPuzAssign: false,
      time: 0,
      showSolution: true,
      forceSubmit: false,
    });

    this.socket.emit(
      "puzzlesubmit",
      {
        studentid: this.props.userData.username,
        roomid: this.props.roomInfo.roomid,
        score: finalScore,
        timetaken: timetaken,
      },
      (status) => {
        console.log("Score submitted to server", status);
        this.setState({ forceSubmit: false, openMessage: true });
      }
    );
  }

  sortAsc = (a, b) => {
    var keyA = new Date(a.pos),
      keyB = new Date(b.pos);
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  };
  handleScoreChangeSudoku = (input) => {
    let newscore = 0;
    let solution = this.state.puzConfig.values.concat(
      this.state.puzConfig.solution
    );
    solution.sort(this.sortAsc);
    input.sort(this.sortAsc);
    console.log("SOLUTION: ", solution);
    console.log("INPUT ", input);
    solution.forEach((sol) => {
      let filtered = input.filter((inp) => inp.pos == sol.pos);
      if (filtered.length > 0) {
        if (Number(filtered[0].val) === Number(sol.val)) {
          newscore += 1;
        } else if (filtered[0].val == "") {
          newscore += 0;
        } else if (Number(filtered[0].val) !== Number(sol.val)) {
          newscore -= 1;
        }
      }
      console.log(filtered, sol, newscore);
    });

    if (newscore < 0) {
      newscore = 0;
    }

    console.log(newscore);
    let timeleft = this.state.time;
    let allotedTime = this.state.totalTIme;
    let timetaken = allotedTime - timeleft;
    let finalScore = newscore;
    console.log("SCORE", finalScore);
    this.setState({
      score: finalScore,
      isPuzAssign: false,
      time: 0,
      showSolution: true,
      forceSubmit: false,
    });

    this.socket.emit(
      "puzzlesubmit",
      {
        studentid: this.props.userData.username,
        roomid: this.props.roomInfo.roomid,
        score: finalScore,
        timetaken: timetaken,
      },
      (status) => {
        console.log("Score submitted to server", status);
        this.setState({ forceSubmit: false, openMessage: true });
      }
    );
  };

  handleLogout = () => {
    localStorage.clear();
    this.setState({
      logout: true,
    });
  };

  calculateSeconds(min, sec) {
    let secs = parseInt(min * 60) + parseInt(sec);
    return secs;
  }

  startTimer() {
    if (!this.timer && this.state.time > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    let newTime = this.state.time - 1;
    this.setState({
      time: newTime,
    });
    console.log(newTime);

    if (newTime <= 0) {
      clearInterval(this.timer);
      this.timer = 0;
      this.setState({
        isPuzAssign: false,
        time: 0,
        showSolution: true,
        forceSubmit: true,
      });
    }
  }

  getMyScore() {
    console.log("Getting my SCORE");
    let config = {
      headers: {
        authorization: `BearerToken ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(`${base_url}getmyscore?roomid=${this.props.roomInfo.roomid}`, config)
      .then(({ data }) => {
        console.log(data);
        this.setState({
          dispScore: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getLeaderboard() {
    console.log("Getting leaderboard");
    let config = {
      headers: {
        authorization: `BearerToken ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(
        `${base_url}leaderboard?roomid=${this.props.roomInfo.roomid}`,
        config
      )
      .then(({ data }) => {
        this.setState({
          leaderboard: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleCloseMessage() {
    this.setState({
      openMessage: false,
    });
  }

  render() {
    const { classes } = this.props;
    console.log(this.props);
    return (
      <Box className={classes.className}>
        {this.state.logout && <Redirect to="/login" />}
        <Snackbar
          open={this.state.openMessage}
          autoHideDuration={6000}
          onClose={this.handleCloseMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">Puzzle Submitted successfully</Alert>
        </Snackbar>
        {this.state.disconnectNotification && (
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">
              <AlertTitle>You Have been disconnected from Internet.</AlertTitle>
              Do not leave the page or you will loose the progress. Please Wait
              we are retrying!
            </Alert>
          </Snackbar>
        )}
        <Grid container className={classes.infoContainer} spacing={2}>
          <Grid xs item className={classes.infoChild}>
            <Typography variant="h6" align="center">
              Room Id:{" "}
            </Typography>
            <Typography variant="body1" style={{ margin: "auto 10px" }}>
              {this.props.roomInfo.roomid}
            </Typography>
          </Grid>
          <Grid xs item className={classes.infoChild}>
            <Typography variant="h6" align="center">
              Room Name:{" "}
            </Typography>
            <Typography variant="body1" style={{ margin: "auto 10px" }}>
              {this.props.roomInfo.roomName}
            </Typography>
          </Grid>

          <Grid xs item className={classes.infoChild}>
            <Typography variant="h6" align="center">
              Teacher:{" "}
            </Typography>
            <Typography variant="body1" style={{ margin: "auto 10px" }}>
              {this.props.roomInfo.createdBy}
            </Typography>
          </Grid>
          <Grid xs item className={classes.infoChild}>
            <Typography variant="h6" align="center">
              Your Current Score:{" "}
            </Typography>
            <Typography variant="body1" style={{ margin: "auto 10px" }}>
              {this.state.dispScore[1] ? this.state.dispScore[1] : "0"}/
              {this.state.dispScore[0] ? this.state.dispScore[0] : "0"}
            </Typography>
          </Grid>
        </Grid>

        <LeaderBoard
          className={classes.leaderdisplay}
          data={this.state.leaderboard}
        />
        <Box className={classes.container}>
          {!this.state.isPuzAssign && !this.state.showSolution && (
            <PleaseWait className={classes.wait} role="student" />
          )}
          {(this.state.isPuzAssign || this.state.showSolution) &&
            !this.state.reload && (
              <Box className={classes.container}>
                {!this.state.showSolution && <Timer time={this.state.time} />}
                {this.state.puzType == "Kenken" ? (
                  <AppCoverStudent
                    config={this.state.puzConfig}
                    role="student"
                    scorehandler={this.handlescorechange}
                    time={this.state.time}
                    submit={this.state.forceSubmit}
                    Solution={this.state.showSolution}
                    isPuz={this.state.isPuzAssign}
                  />
                ) : ( this.state.puzType == "sudoku" &&
                  <RenderSudoku
                    values={this.state.puzConfig.values}
                    view={false}
                    student={true}
                    submit={this.state.forceSubmit}
                    scorehandler={this.handleScoreChangeSudoku}
                    correctSolution={this.state.puzConfig.solution}
                  />
                )}
              </Box>
            )}
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(StudentRoom);
