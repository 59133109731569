import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import FeedbackIcon from "@material-ui/icons/Feedback";
import React from "react";

const useStyles = (theme) => ({
  container: {
    marginTop: "20px",
    width: "250px",
    backgroundColor: "#DFE0E2",
    borderRadius: "20px",
  },
  list: {
    maxHeight: "50vh",
    overflow: "auto",
  },
  score: {
    flex: "1",
    float: "right",
  },
});

const LeaderBoard = (props) => {
  const { classes,type,data } = props;
 
  console.log(data)
  return (
    <Box className={props.className}>
      <Box className={classes.container}>
        <Typography variant="h6" align="center">
          {(type == "activestudents") ? `Active Students (${props.data.length})` : "LeaderBoard" }
        </Typography>
        <Divider />
        {props.data.length == 0 ? (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom:"20px"
            }}
          >
            <FeedbackIcon />
            <Typography variant="body2" align="center">
              No Participants Yet.
            </Typography>
          </Box>
        ) : (

          <TableContainer className={classes.list} style={{maxHeight: (type=="activestudents") ? "80vh" : "50vh"}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {(type == "activestudents") ? <TableCell>Sr. No</TableCell> : <TableCell>Rank</TableCell>}
                  <TableCell>Name</TableCell>
                  {(type == "activestudents") ? null : <TableCell>Score</TableCell>}
                  {/* {(type == "activestudents") ? null : <TableCell>Submitted</TableCell>} */}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data.map((val, index) => {
                  let style = {backgroundColor: "rgb(24,91,218)"}
                  let fontColor=  {color:"white"}
                  if(!val.isSubmitted){
                    style = {}
                    fontColor={}
                  }
                  return (
                
                    <TableRow key={index} style={style}>
                      <TableCell style={fontColor}>{index + 1}</TableCell>
                      <TableCell style={fontColor}>{val.name}</TableCell>
                      {(type == "activestudents") ? null : <TableCell style={fontColor}>{val.tempScore}/{val.score}</TableCell>}
                      {/* {(type == "activestudents") ? null : <TableCell>{val.isSubmitted ? "Yes" :"No"}</TableCell>} */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* <List className={classes.list}>
          {console.log(props)}
          {props.data.map((info, index) => {
            return (
              <Box key={index}>
                <ListItem>
                  <ListItemText
                    primary={index + 1 + ". " + info.studentId}
                    secondary={"score: " + info.score}
                  />
                </ListItem>
                <Divider />
              </Box>
            );
          })}
        </List> */}
      </Box>
    </Box>
  );
};

export default withStyles(useStyles)(LeaderBoard);
