import React, { Component } from "react";
import { KenKenGenerator, MathOperators } from "kengen";
import {Box, Button, IconButton, Tooltip, Typography} from '@material-ui/core'
import RenderKenken from "./RenderKenken";
import AssignmentIcon from '@material-ui/icons/Assignment';
import CreateIcon from '@material-ui/icons/Create';

export default class AppCoverStudent extends Component {
  constructor(props) {
    super(props);
    
    this.calculatescore = this.calculatescore.bind(this);
  }
  
  componentWillMount(){
    const puz = this.props.config;
   
    let array = [];
    for (let i = 0; i < puz.size * puz.size; i++) {
      array[i] = "";
    }

    let sizeArr = [];
    for(let j=1; j<=this.props.config.size;j++){
      sizeArr.push(j)
    }

    let hintVal = []
    for(let i=0; i<81; i++){
      // let inside = []
      hintVal.push({pos: i, pos_i: 1, display: false})
      hintVal.push({pos: i, pos_i: 2, display: false})
      hintVal.push({pos: i, pos_i: 3, display: false})
      hintVal.push({pos: i, pos_i: 4, display: false})
      hintVal.push({pos: i, pos_i: 5, display: false})
      hintVal.push({pos: i, pos_i: 6, display: false})
      hintVal.push({pos: i, pos_i: 7, display: false})
      hintVal.push({pos: i, pos_i: 8, display: false})
      hintVal.push({pos: i, pos_i: 9, display: false})
      // hintVal.push(inside)
    }

    this.setState({
      isSolve: false ,
      checkSolution: this.props.Solution,
      score: 0,
      input:array,
      puz,
      seed: new Date().getTime(),
      alreadySubmitted: false,
      sizeArr: sizeArr,
      activeCell:"",
      hint: false,
      hintVal: hintVal
    });
    console.log(this.props)
  }

  handleActiveCell = (cell) => {
    this.setState({
      activeCell: cell
    })
  }



  handleinputchange = (pos, event) => {
    let value = event.target.value;
    if (isNaN(value)) {
      return null;
    }
    let newinput = [...this.state.input];
    newinput[pos] = value;
    this.setState({
      input: newinput,
    });
  };

  handleinputchangeNew = (val) => {
    let value = val;
    let pos = this.state.activeCell

    if (isNaN(value)) {
      return null;
    }
    if(pos!=="" && !this.state.hint){
      let newinput = [...this.state.input];
      newinput[pos] = value;
      let hintVal = this.state.hintVal;
      let ind = hintVal.findIndex((el) => el.pos == pos)
      hintVal[ind].display = false
      hintVal[ind+1].display = false
      hintVal[ind+2].display = false
      hintVal[ind+3].display = false
      hintVal[ind+4].display = false
      hintVal[ind+5].display = false
      hintVal[ind+6].display = false
      hintVal[ind+7].display = false
      hintVal[ind+8].display = false
      
      this.setState({
        input: newinput,
        hintVal: hintVal
      });
    }
    if(pos!=="" && this.state.hint){
      let hintVal = this.state.hintVal;
      let input = this.state.input;
      if(input[pos]){
        input[pos] = ""
      }
      let ind = hintVal.findIndex((el) => el.pos == pos && el.pos_i == val)
      hintVal[ind].display = !hintVal[ind].display
      this.setState({
        hintVal: hintVal,
        input: input
      });
    }
  };


  calculatescore = () => {
    this.props.scorehandler(this.state.puz.cells,this.state.input)
  }

  componentDidUpdate(){
    if(this.props.submit && !this.state.alreadySubmitted){
      this.setState({checkSolution: true, alreadySubmitted: true})
      this.calculatescore();
    }
  }


  render() {
    return (
      <Box className={this.props.className} style={{display:"flex", flexDirection:"row", userSelect:"none"}}>
        <Box style={{display: 'flex', justifyContent:"center", alignItems:"center", flexDirection:'column', width:"400px"}}>
        <Box>
              {!this.state.alreadySubmitted &&
              <Tooltip title={this.state.hint ? "Add solution" :"Add Hint"}>
                <IconButton onClick={(e) => {e.preventDefault(); this.setState({
                  hint: !this.state.hint
                })}}>
                  <CreateIcon />
                </IconButton>
              </Tooltip>}
              </Box>
          <Box style={{display:"flex", flexDirection:"row"}}>

          
          {/* <Button onClick={() => this.changeseed()} style={{marginBottom:"10px"}} disabled={(this.props.disableGenerate === '')}>Generate new</Button> */}
          <RenderKenken
            settings={this.state.puz}
            solve={this.state.isSolve}
            checksolution={this.state.checkSolution}
            handleinputchange={this.handleinputchange}
            input={this.state.input}
            role={this.props.role}
            handleActiveCell={this.handleActiveCell}
            activeCell={this.state.activeCell}
            hint={this.state.hint}
            hintVal={this.state.hintVal}
          />
          <Box style={{display: "flex", flexDirection:"column",marginLeft:"5px", justifyContent:"center", alignItems:"center"}}>
          {!this.state.checkSolution &&
            this.state.sizeArr.map(val => {
              let wd = "4em"
              let ht = "4em"
              if(this.props.config.size > 5){
                wd = "40px"
                ht = "40px"
              }
              if(this.props.config.size > 8){
                wd = "35px"
                ht = "35px"
              }
              return (
              <div style={{
                width:wd,
                height:ht,
                border:"1px solid black",
                borderRadius:"5px",
                marginBottom:"3px",
                textAlign:"center",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                fontSize:"1em",
                // lineHeight:""
              }}
              onClick={(e) => {e.preventDefault(); this.handleinputchangeNew(val)}}
              >
                {val}
              </div>)
            })
          
          }

        </Box>
        </Box>
          {/* <Button onClick={() => this.setState({ isSolve: true })} endIcon={<AssignmentIcon />}>
            SOLVE
          </Button> */}
          {!this.state.isSolve && (this.props.role==="student") && !this.props.Solution && (
            <Box style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}} >
              
            <Button onClick={
              () => {
                this.setState({alreadySubmitted: true})
                this.setState({ checkSolution: true });
                this.calculatescore();
            }}>
              Submit
            </Button>
            </Box>
          )}
         
        </Box>
        
      </Box>
    );
  }
}
