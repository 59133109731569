import { Box, Paper, Typography } from "@material-ui/core";
import { React, useState, useEffect } from "react";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
const Timer = (props) => {
  console.log(props);

  let minutes = parseInt(props.time / 60);
  let seconds = parseInt(props.time) - minutes*60;

  if(String(seconds).length == 1){
    seconds = '0'+seconds
  }
  if(String(minutes).length == 1){
    minutes = '0'+minutes
  }
  return (
    <div className={props.className}>
      <Box style={{display: "flex", justifyContent:"center", alignItems:"center"}}>
        <HourglassEmptyIcon />
        <Typography>{minutes}:{seconds}</Typography>
      </Box>
    </div>
  );
};

export default Timer;
