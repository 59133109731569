import { React, useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import {CircularProgress} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FormControlLabel, Radio, RadioGroup, Snackbar } from "@material-ui/core";
import { Alert } from '@material-ui/lab'
import axios from 'axios';
import { Redirect } from "react-router";
import { base_url } from "../utils/variables"
import logo from '../utils/logo2.png'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://www.penangmathplatform.com/" target="_blank">
        Penang Math Platform
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent:"center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#8300E9",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo:{
    width:"100%",
    height:"150px"
  }
}));

export default function Signin() {
  const classes = useStyles();

  let [userName, setUserName] = useState("");
  let [password, setPassword] = useState("");
  let [role, setRole] = useState("");
  let [error, setError] = useState({
      display: false,
      message:""
  });
  let [isLoading, setisLoading] = useState(false);

  let [loginSucess, setLoginsucess] = useState(false)

  const handleUsernameChange = (e) => {
    //   e.preventDefault();
    setUserName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    // e.preventDefault();
    setPassword(e.target.value);
  };

  const handleRoleChange = (e) => {
    // e.preventDefault();
    setRole(e.target.value)
  }

  const handleSignIn = () => {
      if(!userName || !password || !role){
          console.log("error")
          setError({
              display:true,
              message:"Invalid Username/Password/Role"
          })
      }

      setisLoading(true);

      axios.post(`${base_url}login`, {
          username: userName,
          role: role,
          password: password
      })
      .then(data => {
          console.log(data.data)
          localStorage.setItem('token', data.data.token);
          setLoginsucess(true);
          setisLoading(false);
           
      })
      .catch((err) => {
          console.log(err)
          setError({
              display: true,
              message: err.response.data.error
          });
          setLoginsucess(false);
          setisLoading(false);
      })

  }

  const closeError = () => {
      setError(false)
  }

  console.log(userName, password, role);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {loginSucess && <Redirect to={{pathname:"/dashboard"}}/>}
      <Snackbar
        open={error.display}
        autoHideDuration={6000} 
        onClose={closeError} 
        anchorOrigin={{vertical:"top", horizontal:"center"}}
        >
        <Alert  severity="error">
            {error.message}
        </Alert>
        </Snackbar> 
      <div className={classes.paper}>
        <img src={logo} className={classes.logo}/>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={userName}
            onChange={(e) => handleUsernameChange(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => handlePasswordChange(e)}
          />
          <RadioGroup value={role} onChange={handleRoleChange} row>
            <FormControlLabel
              value="teacher"
              control={<Radio color="primary"/>}
              label="Teacher"
            />
            <FormControlLabel
              value="student"
              control={<Radio color="primary"/>}
              label="Student"
            />
            <FormControlLabel
              value="admin"
              control={<Radio color="primary"/>}
              label="admin"
            />
          </RadioGroup>
          {(!isLoading) ? <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSignIn}
          >
            Sign In
          </Button> : <CircularProgress style={{marginLeft:"50%"}}/>}
          <Grid container>
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid> */}
            {/* <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid> */}
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
