import React, { Component } from "react";
import { KenKenGenerator, MathOperators } from "kengen";
import {Box, Button} from '@material-ui/core'
import RenderKenken from "./RenderKenken";
import AssignmentIcon from '@material-ui/icons/Assignment';
export default class AppCover extends Component {
  constructor(props) {
    super(props);
    const puz = KenKenGenerator.generate({
      size: this.props.size,
      seed: new Date().getTime(),
      // operations: [
      //   MathOperators.ADDITION,
      //   MathOperators.SUBTRACTION,
      //   MathOperators.MULTIPLICATION,
      //   MathOperators.DIVISION,
      // ],
      operations: this.props.operations
    });
   
    let array = [];
    for (let i = 0; i < puz.size * puz.size; i++) {
      array[i] = "";
    }
    this.state = {
      isSolve: false,
      checkSolution: false,
      score: 0,
      input:array,
      puz,
      seed: new Date().getTime()
    };
    console.log(this.props)
    this.props.updatePuz(this.state.puz)
  }
  

  changeseed = () => {
    let newSize = parseInt(this.props.size)
    console.log("NEWSIZE", newSize)
    let newseed =  new Date().getTime() 
    const puz = KenKenGenerator.generate({
        size: newSize,
        seed: newseed,
        // operations: [
        //   MathOperators.ADDITION,
        //   MathOperators.SUBTRACTION,
        //   MathOperators.MULTIPLICATION,
        //   MathOperators.DIVISION,
        // ],
        operations: this.props.operations
      });
      this.props.updatePuz(puz)
    let array = [];
    for (let i = 0; i < puz.size * puz.size; i++) {
      array[i] = "";
    }
    this.setState({
        isSolve: false,
        checkSolution: false,
        score: 0,
        input:array,
        puz,
        seed: newseed
    })
    
    
  }

  handleinputchange = (pos, event) => {
    let value = event.target.value;
    if (isNaN(value)) {
      return null;
    }
    let newinput = [...this.state.input];
    newinput[pos] = value;
    this.setState({
      input: newinput,
    });
  };


  calculatescore = () => {
    let newscore =0
    this.state.puz.cells.forEach((val,index) => {
      if(Number(this.state.input[index]) === val){
        newscore +=1
      }
      else if(Number(this.state.input[index]) !== val){
        newscore -=1
      }
      
    //   console.log(val,this.state.input[index],this.state.input[index] == val, this.state.score)
    })
    this.setState({
        score: newscore
    })
  }

  render() {
    
    // console.log(this.state.puz);
    // console.log(this.state.isSolve);
    // console.log(this.props.disableGenerate, "DISABLE")
    return (
      <Box className={this.props.className}>
        <Box style={{display: 'flex', justifyContent:"center", flexDirection:'column'}}>
          {console.log(this.state.isSolve)}
          <Button onClick={() => this.changeseed()} style={{marginBottom:"10px"}} disabled={(this.props.disableGenerate === '')}>Generate new</Button>
          <RenderKenken
            settings={this.state.puz}
            solve={this.state.isSolve}
            checksolution={this.state.checkSolution}
            handleinputchange={this.handleinputchange}
            input={this.state.input}
            role={this.props.role}
          />
          <Button onClick={() => this.setState({ isSolve: true })} endIcon={<AssignmentIcon />}>
            SOLVE
          </Button>
          {!this.state.isSolve && (this.props.role==="student") && (
            <Button onClick={
              () => {
                this.setState({ checkSolution: true });
                this.calculatescore();
            }}>
              Check Solution
            </Button>
          )}
          
          {
            this.state.checkSolution && !this.state.isSolve &&
            <span> FINAL SCORE: {this.state.score}</span>
          }
        </Box>
      </Box>
    );
  }
}
