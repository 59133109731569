import { Button, TextField } from "@material-ui/core";
import React, { Component } from "react";

class Cell extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // console.log("HINT: ", this.props.hint);
    let cellStyle = {
    //   width: "10px" ,
    //   height: ,
      textAlign: "center",
      borderTop: "1px solid gray",
      borderBottom: "1px solid gray",
      borderLeft: "1px solid gray",
      borderRight: "1px solid gray",
      position: "relative",
      backgroundColor: "white",
      ...this.props.cellStyle,
    };
    let { hints, pos, groups, size, addSolution } = this.props;
    let text = "";
    let adj_pos = {
      top: pos - parseInt(size),
      left: pos - 1,
      right: pos + 1,
      bottom: pos + parseInt(size),
    };
    groups.forEach((val) => {
      const cells = new Set(val);
      console.log(cells);
      // console.log("EEEE", JSON.stringify(cells), JSON.stringify(groups), JSON.stringify(val))
      console.log(hints);
      hints.forEach((hint) => {
        if (pos === hint.pos) {
          text = hint.operator;
          // if(val.length == 1){
          //   text = " ";
          // }
          text = text + " " + hint.value;
        }
      });

      if (cells.has(pos)) {
        if (!cells.has(adj_pos.top)) {
          cellStyle.borderTop = "3px solid black";
        }
        if (!cells.has(adj_pos.bottom)) {
          console.log("here", pos, adj_pos.bottom);
          // console.log("EEEE", JSON.stringify(cells), JSON.stringify(groups), JSON.stringify(val))
          cellStyle.borderBottom = "3px solid black";
        }
        if (!cells.has(adj_pos.left)) {
          cellStyle.borderLeft = "3px solid black";
        }
        if (!cells.has(adj_pos.right)) {
          cellStyle.borderRight = "3px solid black";
        }
        if (pos % size === 0) {
          cellStyle.borderLeft = "3px solid black";
        }
        if ((pos + 1) % size === 0) {
          cellStyle.borderRight = "3px solid black";
        }
      }
    });
    return (
      <td
        style={cellStyle}
        onClick={(e) => {
          e.preventDefault();
          console.log("click");
          if (!addSolution) {
            this.props.handleSelect(this.props.pos);
          }
        }}
      >
        {/* {(hint.pos === pos ) ? <div>{hint.operator}{hint.value}</div> : <div>{pos}</div> } */}
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            fontSize: "0.7em",
          }}
        >
          {text}
        </div>
        {addSolution ? (
          <input
            style={{
              height: "95%",
              width: "95%",
              border: "0",
              textAlign: "center",
              fontSize: "0.9em",
              // fontWeight:,
            }}
            value={this.props.solution}
            onChange={(e) => {
              e.preventDefault();
              this.props.handleSolutionChange(e.target.value, this.props.pos);
            }}
          />
        ) : (
          this.props.solution
        )}
      </td>
    );
  }
}

// #------------------#-------------------#------------------------#-----------------#-----------------------

export default class CreatePuzzle extends Component {
  constructor(props) {
    super(props);
    let size = this.props.size;
    // let size = 3
    let array = [];
    for (let i = 0; i < size * size; i++) {
      array[i] = "";
    }

    this.state = {
      size: size,
      selectedCells: [],
      groupHint: [
        {
          pos: "",
          operator: "",
          value: "",
        },
      ],
      hintval: "",
      groups: [],
      addHintDisable: true,
      solution: array,
      addSolution: false,
      puzConfig: {},
      confirm: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSize = this.handleSize.bind(this);
    this.handleHintChange = this.handleHintChange.bind(this);
    this.addHint = this.addHint.bind(this);
    this.handleAddSolution = this.handleAddSolution.bind(this);
    this.handleSolution = this.handleSolution.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleSelect(pos) {
    let selectedCells = this.state.selectedCells;
    let groups = this.state.groups;
    let groupHint = this.state.groupHint;
    let yes = true;
    for (let i = 0; i < groups.length; i++) {
      let grp = groups[i];
      if (grp.includes(pos)) {
        if (
          window.confirm(
            "The selected cell is already in a group \n do you want to remove its group?"
          )
        ) {
          console.log("YES");
          let sols = this.state.solution;
          console.log(sols, grp);
          grp.forEach((val) => {
            sols[val] = "";
          });
          console.log(sols);
          // if (this.state.confirm) {
          //   this.props.toggleConfirm();
          // }
          this.setState({
            solution: sols,
            confirm: false,
          });
          this.props.toggleConfirm(false);
          groups.splice(i, 1);
          groupHint.splice(i + 1, 1);
          break;
        } else {
          console.log("NO");
          yes = false;
        }
      }
    }
    this.setState({
      groups: groups,
      groupHint: groupHint,
    });

    if (yes) {
      if (selectedCells.includes(pos)) {
        selectedCells.splice(selectedCells.indexOf(pos), 1);
      } else {
        selectedCells.push(pos);
      }
      this.setState({
        selectedCells: selectedCells,
      });
    }
    if (this.state.selectedCells.length > 0 && this.state.hintval) {
      this.setState({
        addHintDisable: false,
      });
    } else {
      this.setState({
        addHintDisable: true,
      });
    }
    console.log(this.state.selectedCells);
    // if(this.s)
  }

  handleSize(e) {
    e.preventDefault();
    this.setState({
      size: e.target.value,
      selectedCells: [],
      groupHint: [
        {
          pos: "",
          operator: "",
          value: "",
        },
      ],
    });
  }

  handleHintChange(e) {
    e.preventDefault();
    if (!isNaN(e.target.value)) {
      this.setState(
        {
          hintval: e.target.value,
        },
        () => {
          if (this.state.selectedCells.length > 0 && this.state.hintval) {
            this.setState({
              addHintDisable: false,
            });
          } else {
            this.setState({
              addHintDisable: true,
            });
          }
        }
      );
    }
  }

  addHint(op) {
    // console.log(this.state.selectedCells)
    let AdjVal = this.checkAdjacent();

    if (AdjVal[0]) {
      this.setState(
        {
          groups: AdjVal[1],
        },
        () => {
          console.log("G: ", this.state.groups);

          let pos = Math.min(...this.state.selectedCells);
          console.log(pos);
          this.setState(
            {
              groupHint: [
                ...this.state.groupHint,
                {
                  pos: pos,
                  operator: op,
                  value: this.state.hintval,
                },
              ],
              selectedCells: [],
            },
            () => {
              if (this.state.selectedCells.length > 0 && this.state.hintval) {
                this.setState({
                  addHintDisable: false,
                });
              } else {
                this.setState({
                  addHintDisable: true,
                });
              }
            }
          );
        }
      );
    }
    // console.log("GROUP :", AdjVal[0],  this.state.groups);
  }

  checkAdjacent() {
    let cells = this.state.selectedCells.sort(function (a, b) {
      return a - b;
    });
    console.log(cells);
    let tempGrp = [];
    let UniquetempGrp = [];
    // let allAdjgrp = [];
    for (let m = 0; m < cells.length; m++) {
      let val = cells[m];
      console.log("checking", val, "in ", tempGrp);
      // let g = this.checkInGrp(val, tempGrp);
      // console.log(g[0], g[1]);
      // let inGrp = g[0];
      // let grpIndex = g[1];
      // if (!inGrp) {
      let adjacents = [];
      adjacents[0] = val - parseInt(this.state.size);
      adjacents[1] = parseInt(val) - 1;
      adjacents[2] = parseInt(val) + 1;
      adjacents[3] = val + parseInt(this.state.size);
      // let noadj = true;
      console.log("before splice", adjacents);
      console.log(val);
      if (val % this.state.size === 0) {
        adjacents.splice(1, 1);
      } else if ((val + 1) % this.state.size === 0) {
        adjacents.splice(2, 1);
      }

      // if(val % this.state.size === 1){

      // }
      // adjacents.push(val)

      for (let k = 0; k < adjacents.length; k++) {
        let adj = adjacents[k];
        console.log("checking for ", adj);
        if (adj > this.state.size * this.state.size - 1 || adj < 0) {
          adjacents.splice(k, 1);
          k -= 1;
        } else if (!cells.includes(adj)) {
          adjacents.splice(k, 1);
          k -= 1;
        }
      }
      adjacents.push(val);
      console.log("Adj: ", adjacents);
      tempGrp.push(adjacents);
      tempGrp.forEach((grp) => {
        grp.sort(function (a, b) {
          return a - b;
        });
      });
      let StringArray = tempGrp.map(JSON.stringify);
      // console.log("STRING", StringArray)
      let UniqueStringArray = new Set(StringArray);
      console.log(UniqueStringArray);
      UniquetempGrp = Array.from(UniqueStringArray, JSON.parse);
    }
    console.log("GRP:", UniquetempGrp);
    // let finalGrp = [UniquetempGrp[0]];
    let finalGrps = UniquetempGrp[0];
    let iscommon = false;
    for (let n = 0; n < UniquetempGrp.length; n++) {
      let grp1 = finalGrps;
      let grp2 = UniquetempGrp[n];
      // if(n!==m){
      iscommon = this.isCommon(grp1, grp2);
      if (iscommon && JSON.stringify(grp1) !== JSON.stringify(grp2)) {
        let a = [...grp1, ...grp2];
        a = a.filter(function (item, pos) {
          return a.indexOf(item) == pos;
        });
        finalGrps = a;
      }
    }
    console.log("FINAL", finalGrps);
    if (finalGrps.length !== cells.length) {
      alert("Invalid Selections");
      return false;
    } else {
      console.log("nice");
      let k = [...this.state.groups, this.state.selectedCells];

      return [true, k];
    }
    // }
  }

  checkInGrp(val, group) {
    let inGrp = false;
    let index;
    for (let i = 0; i < group.length && !inGrp; i++) {
      let grp = group[i];
      index = i;
      for (let j = 0; j < grp.length; j++) {
        let grpval = grp[j];
        console.log("comparing", val, grpval);
        if (val == grpval) {
          inGrp = true;
          // return (inGrp)
          break;
        }
      }
    }
    return [inGrp, index];
  }

  isCommon(grp1, grp2) {
    let noCommon = true;
    console.log(
      "finding common between ",
      JSON.stringify(grp1),
      JSON.stringify(grp2)
    );
    for (let i = 0; i < grp1.length && noCommon; i++) {
      let val1 = grp1[i];
      for (let j = 0; j < grp2.length; j++) {
        let val2 = grp2[j];
        if (val1 == val2) {
          noCommon = false;
          break;
        }
      }
    }
    console.log(!noCommon);
    return !noCommon;
  }

  handleAddSolution(a) {
    this.setState({
      addSolution: a,
    });
  }

  handleSolution(val, pos) {
    let array = this.state.solution;
    if ((parseInt(val) > 0 && parseInt(val) <= this.state.size) || val === "") {
      array[pos] = val;
      this.props.toggleConfirm(false);
      this.setState({
        solution: array,
        confirm: false,
      });
    }
  }

  //IMPORTANT DONOT CHANGE
  componentDidUpdate(prevProps) {
    console.log(JSON.stringify(this.props.size), JSON.stringify(prevProps.size))
    if (this.props.size !== prevProps.size) {
      this.props.toggleConfirm(false);
      // console.log(nextProps.size)
      this.setState({
        confirm: false,
        addSolution: false
      })
      let array = [];
      for (let i = 0; i < this.props.size * this.props.size; i++) {
        array[i] = "";
      }
      this.setState({
        size: this.props.size,
        selectedCells: [],
        groupHint: [
          {
            pos: "",
            operator: "",
            value: "",
          },
        ],
        groups: [],
        solution: array,
        confirm: false,
      });
    }
  }

  handleConfirm() {
    this.props.toggleConfirm(true);
    // if(this.state.confirm){
    //   this.props.toggleConfirm(false)
    // }
    this.setState(
      {
        confirm: !this.state.confirm,
      },
      () => {
        if (this.state.confirm) {
          let config = {};
          config.cells = this.state.solution;
          config.math = [];
          this.state.groups.forEach((grp) => {
            grp = grp.map(Number);
            let obj = {
              cells: grp,
            };
            this.state.groupHint.forEach((hint) => {
              if (grp.includes(hint.pos)) {
                obj.operation = hint.operator;
                obj.result = hint.value;
              }
            });
            config.math.push(obj);
          });
          config.size = this.state.size;
          console.log("CONFIG ,", config);
          this.setState(
            {
              puzConfig: config,
            },
            () => {
              this.props.updatePuzConfig(this.state.puzConfig);
            }
          );
        }
      }
    );
  }

 
  render() {
    console.log(this.state.solution)
    let rows = [];
    for (let y = 0; y < this.state.size; y++) {
      let cells = [];
      for (let x = 0; x < this.state.size; x++) {
        let pos = y * this.state.size + x;
        let cellStyle = {
          width: "3em",
          height: "3em",
          // border: "1px solid black",
        };
        if (this.state.selectedCells.includes(pos)) {
          cellStyle = {
            width: "3em",
            height: "3em",
            // border: "1px solid black",
            backgroundColor: "rgb(173,216,230)",
          };
        } else {
          cellStyle = {
            width: "3em",
            height: "3em",
            // border: "1px solid black",
          };
        }
        if(this.state.size > 5){
            cellStyle.width = "2.7em"
            cellStyle.height = "2.7em"
        }
        cells.push(
          <Cell
            key={pos}
            pos={pos}
            handleSelect={this.handleSelect}
            cellStyle={cellStyle}
            hints={this.state.groupHint}
            groups={this.state.groups}
            size={this.state.size}
            solution={this.state.solution[pos]}
            addSolution={this.state.addSolution}
            handleSolutionChange={this.handleSolution}
          />
        );
      }
      rows.push(<tr key={y}>{cells}</tr>);
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          flexDirection: "column",
        }}
      >

        <table cellPadding={0} cellSpacing={0} style={{ alignSelf: "center" }}>
          <tbody>{rows}</tbody>
        </table>
        {/* <input
          type="number"
          value={this.state.size}
          onChange={this.handleSize}
        ></input> */}
        {/* <TextField type="number"
          value={this.state.size}
          onChange={this.handleSize}/> */}
        {/* <input
          placeholder="Value"
          value={this.state.hintval}
          onChange={this.handleHintChange}
        ></input> */}
        <TextField
          placeholder="Value"
          value={this.state.hintval}
          onChange={this.handleHintChange}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            size="small"
            onClick={(e) => {
              e.preventDefault();
              this.addHint("+");
            }}
            variant="outlined"
            disabled={this.state.addHintDisable}
            style={{ marginRight: "5px" }}
          >
            +
          </Button>
          {/* <Button size="small"
            onClick={(e) => {
              e.preventDefault();
              this.addHint("+");
            }}
            disabled={this.state.addHintDisable}
          >
            +
          </Button> */}
          <Button
            size="small"
            onClick={(e) => {
              e.preventDefault();
              this.addHint("-");
            }}
            disabled={this.state.addHintDisable}
            variant="outlined"
            style={{ marginRight: "5px" }}
          >
            -
          </Button>
          <Button
            size="small"
            onClick={(e) => {
              e.preventDefault();
              this.addHint("x");
            }}
            variant="outlined"
            disabled={this.state.addHintDisable}
            style={{ marginRight: "5px" }}
          >
            X
          </Button>
          <Button
            size="small"
            onClick={(e) => {
              e.preventDefault();
              this.addHint("/");
            }}
            variant="outlined"
            disabled={this.state.addHintDisable}
          >
            /
          </Button>
        </div>
        {this.state.groups.flat().length ===
          this.state.size * this.state.size && !this.state.addSolution ? (
          <div>
            {/* <span>Cell {this.state.solution.length + 1} :</span>
          <input /> */}
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.handleAddSolution(true);
              }}
            >
              Add Solutions
            </Button>
          </div>
        ) : null}
        {this.state.addSolution ? (
          <div>
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.handleAddSolution(false);
              }}
            >
              Edit Grid
            </Button>
            {!this.state.confirm ? (
              
              <div>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.handleConfirm();
                }}
                disabled={this.state.solution.includes("")}
              >
                Confirm
              </Button>
              </div>
            ) : (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.handleConfirm();
                }}
                disabled={this.state.solution.includes("") || this.state.solution.length === 0}
              >
                Cancel
              </Button>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}
