import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  withStyles,
  Snackbar,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@material-ui/core";
import React, { Component } from "react";
import Appbar from "./Appbar";
import axios from "axios";
import ShareIcon from "@material-ui/icons/Share";
import { base_url } from "../utils/variables";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CreatePuzzle from "./CreatePuzzle";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { Alert, Autocomplete } from "@material-ui/lab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import RenderKenken from "./RenderKenken";
import Sudoku from "./Sudoku";
import RenderSudoku from "./RenderSudoku";

const styles = (theme) => ({
  container: {
    marginTop: "30px",
    width: "30%",
    marginLeft: "10px",
  },
  table: {
    maxHeight: "80vh",
    display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    minHeight: "10vh",
  },
  puzzle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    marginTop: "20px",
    // width: "50%",
    alignItems: "center",
    "& .MuiTextField-root": {
      marginLeft: "20px",
      width: "8em",
    },
  },
  nameModal: {
    position: "absolute",
    // width: 450,
    // height: "90%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    // border: '2px s',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    height: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  puzzleView: {
    position: "absolute",
    // width: 450,
    // height: "90%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    // border: '2px s',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "400px",
    // height: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  shareView: {
    position: "absolute",
    // width: 450,
    // height: "90%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    // border: '2px s',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    // height: "400px",
    paddingBottom: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
  },
});

class Puzzlebank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: this.props.userData,
      puzzleList: [],
      puzSize: 3,
      confirm: false,
      config: {},
      puzName: "",
      nameModalOpen: false,
      listLoading: true,
      message: {
        display: false,
        severity: "success",
        data: "",
      },
      viewData: {},
      viewPuzzle: false,
      viewType:"",
      sharePuzzle: false,
      teacherLoading: false,
      teacherList: [],
      teachersToShare: [],
      puzzleToShare: "",
      puzType: "Kenken",
    };
  }

  componentDidMount() {
    this.getPuzzlelist();
  }

  getPuzzlelist = () => {
    this.setState({
      listLoading: true,
    });
    axios
      .get(`${base_url}getpuzzlelist?teacherid=${this.state.userData.username}`)
      .then(({ data }) => {
        this.setState({
          puzzleList: data,
          listLoading: false,
        });
        // console.log(this.state);
      })
      .catch((err) => {
        this.setState({
          listLoading: false,
        });
      });
  };

  handleSizeChange = (e) => {
    e.preventDefault();
    if (!isNaN(e.target.value) && e.target.value < 10) {
      this.setState({
        puzSize: e.target.value,
      });
    }
  };

  handleConfirm = (condition) => {
    this.setState({
      confirm: condition,
    });
  };

  updatePuzConfig = (data) => {
    this.setState({
      config: data
    });
  };

  OpenNameModal = () => {
    this.setState({
      nameModalOpen: true,
    });
  };

  CloseNameModal = () => {
    this.setState({
      nameModalOpen: false,
    });
  };

  handleSave = () => {
    axios
      .post(`${base_url}createnewpuzzle`, {
        teacherid: this.state.userData.username,
        puzType: this.state.puzType,
        config: this.state.config,
        puzzleName: this.state.puzName,
      })
      .then((data) => {
        console.log(data);
        this.CloseNameModal();
        this.getPuzzlelist();
        this.setState({
          puzSize: 3,
          message: {
            display: true,
            data: "Puzzle Saved Successfully",
            severity: "success",
          },
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          message: {
            display: true,
            data: "Puzzle Save failed. Try Again!",
            severity: "error",
          },
        });
      });
  };

  deletePuzzle = (id) => {
    axios
      .delete(`${base_url}deletepuzzle?_id=${id}`)
      .then((data) => {
        console.log(data);
        this.setState({
          message: {
            display: true,
            data: "Puzzle delete success.",
            severity: "success",
          },
        });
        this.getPuzzlelist();
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          message: {
            display: true,
            data: "Puzzle delete failed. Try Again!",
            severity: "error",
          },
        });
      });
  };

  handlePuzzleView = (data) => {
    this.setState({
      viewData: data.puzzleConfig,
      viewPuzzle: true,
      viewType:data.puzType
    });
  };

  handleSharePuzzle = (id) => {
    console.log(id);
    this.setState({
      sharePuzzle: true,
      teacherLoading: true,
      puzzleToShare: id,
    });
    axios
      .get(`${base_url}listteachers`)
      .then(({ data }) => {
        console.log(data);
        this.setState({
          teacherList: data,
          teacherLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          teacherLoading: false,
        });
      });
  };

  confirmSharePuzzle = (e) => {
    e.preventDefault();
    let id = this.state.puzzleToShare;
    let teachers = this.state.teachersToShare.map((val) => val.username);

    axios
      .post(`${base_url}sharepuzzle`, {
        _id: id,
        shareWith: teachers,
      })
      .then(({ data }) => {
        console.log(data);
        this.setState({
          sharePuzzle: false,
          puzzleToShare: "",
          teachersToShare: [],
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          sharePuzzle: false,
          puzzleToShare: "",
          teachersToShare: [],
        });
      });
  };

  handlePuzChange = (e) => {
    this.setState({
      puzType: e.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    console.log("CONFIG", this.state.config);
    return (
      <div>
        <Snackbar
          open={this.state.message.display}
          autoHideDuration={2000}
          onClose={() => {
            this.setState({
              message: {
                display: false,
                data: "",
                severity: "success",
              },
            });
          }}
        >
          <Alert severity={this.state.message.severity}>
            {this.state.message.data}
          </Alert>
        </Snackbar>
        <Appbar view="Create Room" role="teacher" inCreateRoom={false} />

        <Box className={classes.container}>
          <TableContainer className={classes.table} component={Paper}>
            {this.state.listLoading && <CircularProgress />}
            {this.state.puzzleList.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  flexAlign: "center",
                  justifyContent: "center",
                  width: "100%",
                  padding: "10px 10px 10px 10px",
                }}
              >
                <FeedbackIcon />
                <span>No saved puzzles found. Create One</span>
                {/* <br></br> */}
                {/* <span>Try Creating One.</span> */}
              </div>
            ) : (
              ""
            )}
            {!this.state.listLoading && this.state.puzzleList.length !== 0 && (
              <Table>
                <colgroup>
                  <col width="50%" />
                  <col width="10%" />
                  <col width="40%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>Puzzle Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.puzzleList.map((data) => (
                    <TableRow key={data._id}>
                      <TableCell>{data.puzzleName}</TableCell>
                      <TableCell>
                        {(data.puzType == "Sudoku") ? "Sudoku" : "Kenken ("+data.puzzleConfig.size + "x" + data.puzzleConfig.size+")"}
                      </TableCell>
                      <TableCell>
                        <Box style={{ display: "flex" }}>
                          <Tooltip title="Delete puzzle">
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                this.deletePuzzle(data._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              this.handlePuzzleView(data);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <Tooltip title="Share puzzle">
                            <IconButton
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleSharePuzzle(data._id);
                              }}
                            >
                              <ShareIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Box>

        <Box className={classes.puzzle}>
          <Box>
            <FormControl>
              <RadioGroup
                value={this.state.puzType}
                onChange={this.handlePuzChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Kenken"
                  control={<Radio />}
                  label="Kenken"
                />
                <FormControlLabel
                  value="Sudoku"
                  control={<Radio />}
                  label="Sudoku"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {this.state.puzType === "Kenken" ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                placeholder="size"
                value={this.state.puzSize}
                onChange={this.handleSizeChange}
              ></TextField>
              <CreatePuzzle
                size={this.state.puzSize}
                toggleConfirm={this.handleConfirm}
                updatePuzConfig={this.updatePuzConfig}
              />
            </Box>
          ) : 
          (
            <Sudoku 
            toggleConfirm={this.handleConfirm}
            updatePuzConfig={this.updatePuzConfig}
             />
          )}
          <Button
                disabled={!this.state.confirm}
                onClick={this.OpenNameModal}
              >
                Save
              </Button>
        </Box>
        <Modal open={this.state.nameModalOpen} onClose={this.CloseNameModal}>
          <Paper className={classes.nameModal}>
            {/* <Typography>Name of Puzzle</Typography> */}
            <TextField
              placeholder="Enter name of Puzzle"
              value={this.state.puzName}
              onChange={(e) => {
                e.preventDefault();
                this.setState({
                  puzName: e.target.value,
                });
              }}
            />
            <Box>
              <Button disabled={!this.state.puzName} onClick={this.handleSave}>
                Save
              </Button>
              <Button onClick={this.CloseNameModal}> Cancel</Button>
            </Box>
          </Paper>
        </Modal>
        <Modal
          open={this.state.viewPuzzle}
          onClose={() => {
            this.setState({ viewPuzzle: false });
          }}
        >
          <Paper className={classes.puzzleView}>
            {this.state.viewType=="Kenken" ?
            <RenderKenken
              settings={this.state.viewData}
              solve={true}
              checksolution={false}
              // handleinputchange={this.handleinputchange}
              // input={this.state.input}
              role="teacher"
            />
            :
            <RenderSudoku values={this.state.viewData.values} view={true} />
          }
          </Paper>
        </Modal>

        <Modal
          open={this.state.sharePuzzle}
          onClose={() => {
            this.setState({
              sharePuzzle: false,
              teachersToShare: [],
              puzzleToShare: "",
            });
          }}
        >
          <Paper className={classes.shareView}>
            <Typography variant="h6">Share Puzzle</Typography>
            {/* <TextField
            label="Share with"
            variant="outlined" 
            /> */}
            <Autocomplete
              multiple
              value={this.state.teachersToShare}
              onChange={(e, newValue) => {
                e.preventDefault();
                this.setState({
                  teachersToShare: newValue,
                });
                console.log(this.state.teachersToShare, "TE");
              }}
              options={this.state.teacherList}
              style={{ width: 300, marginTop: "20px" }}
              loading={this.state.teacherLoading}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Share with"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {this.state.teacherLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <Button
              style={{ marginTop: "20px" }}
              disabled={this.state.teachersToShare.length === 0}
              onClick={this.confirmSharePuzzle}
            >
              Share
            </Button>
          </Paper>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(Puzzlebank);
