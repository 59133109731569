import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  withStyles,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer
} from "@material-ui/core";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Appbar from "./Appbar";
import axios from "axios";
import TeacherRoom from "./TeacherRoom";
import { base_url } from '../utils/variables'
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from '@material-ui/icons/Delete';
import { Socket } from "socket.io-client";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    "& .MuiButtonBase-root": {
      backgroundColor: "#3F51B5",
      color: "white",
      marginLeft: "20px",
      marginTop: "10px",
    },
  },
});

class CreateRoom extends Component {
  state = {
    isLoading: false,
    roomName: "",
    roomDetails: "",
    roomList:[]
  };

  componentDidMount(){
    this.getRoomList()
  }

  handleRoomnameChange = (e) => {
    e.preventDefault();
    this.setState({
      roomName: e.target.value,
    });
  };

  handleCreateRoom = () => {
    if (this.state.roomName) {
      this.setState({
        isLoading: true,
      });
      let config = {
        headers: {
          authorization: `BearerToken ${localStorage.getItem("token")}`,
        },
      };
      axios
        .post(
          `${base_url}createroom`,
          { room_name: this.state.roomName },
          config
        )
        .then((data) => {
          console.log(data);
          this.setState({
            isLoading: false,
            roomDetails: data.data,
          });
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          if (err.response.status === 403) {
            this.props.history.push("/login");
          }
        });
    }
  };

  handleJoinRoom(roomid,e){
    e.preventDefault();
    let config = {
      headers: {
        authorization: `BearerToken ${localStorage.getItem("token")}`,
      },
    };
    axios.get(`${base_url}getroominfo?roomid=${roomid}`, config)
    .then((data) => {
      console.log(data);
      this.setState({
        isLoading: false,
        roomDetails: data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      console.log(err.response);
      if (err.response.status === 403) {
        this.props.history.push("/login");
      }
    });
  }

  getRoomList() {
    this.setState({
      loading: true
    })
    let config = {
      headers: {
        authorization: `BearerToken ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(`${base_url}mypastrooms`, config)
      .then((data) => {
        this.setState({
          roomList: data.data,
          loading: false
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleDeleteRoom(roomid){
    let config = {
      headers: {
        authorization: `BearerToken ${localStorage.getItem("token")}`,
      },
    };

    axios.delete(`${base_url}deleteroom?roomId=${roomid}`, config)
    .then(response => {
      this.getRoomList();
    })
    .catch(err => {
      console.log(err)
    })
  }

  render() {
    const { classes } = this.props;
    if (this.props.userData.role === "student") {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <Appbar view="Create Room" role={this.props.userData.role} inCreateRoom={(this.state.roomDetails) ? true : false}/>
        <Box className={classes.container}>
        {/* <Snackbar
          open={this.state.error.display}
          autoHideDuration={3000}
          onClose={this.closeError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">{this.state.error.message}</Alert>
        </Snackbar> */}
          {!this.state.isLoading && !this.state.roomDetails && (
            <Box>
              <TextField
                id="outlined-basic"
                label="Room Name"
                variant="outlined"
                value={this.state.roomName}
                onChange={this.handleRoomnameChange}
              />
              <Button onClick={this.handleCreateRoom}>
                {" "}
                Create A new Room
              </Button>
            </Box>
          )}
          {this.state.isLoading && <CircularProgress />}
        </Box>
        {!this.state.roomDetails &&
          <Box>
        <Divider style={{marginTop:"10px"}}/>
        <TableContainer component={Paper}>
          <Table>
            <colgroup>
              <col width="10%" />
              <col width="20%" />
              <col width="50%" />
              <col width="20%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Sr. No</TableCell>
                <TableCell>RoomId</TableCell>
                <TableCell>Room Name</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.roomList.map((data, index) => (
                <TableRow key={data.roomid}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{data.roomid}</TableCell>
                  <TableCell>{data.roomName}</TableCell>
                  <TableCell>
                    <Button
                      endIcon={<AddBoxIcon />}
                      onClick={(e) => {this.handleJoinRoom(data.roomid,e)}}
                    >
                      Join
                    </Button>
                    <Button
                      style={{marginLeft:"10px"}}
                      endIcon={<DeleteIcon style={{color:"red"}}/>}
                      onClick={(e) => {this.handleDeleteRoom(data.roomid)}}
                    >
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>}

        {this.state.roomDetails && (
          <TeacherRoom roomDetails={this.state.roomDetails} userData={this.props.userData} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CreateRoom);
