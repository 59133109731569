import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  withStyles,
  FormHelperText,
  Snackbar,
  Modal,
  Paper,
  List,
  ListItem,
  CircularProgress,
} from "@material-ui/core";
import { MathOperators } from "kengen";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import React, { Component } from "react";
import axios from "axios";
import LeaderBoard from "./LeaderBoard";
import AppCover from "./AppCover";
import { io } from "socket.io-client";
import PleaseWait from "./PleaseWait";
import Timer from "./Timer";
import { CSVLink, CSVDownload } from "react-csv";
import { base_url } from "../utils/variables";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditRoomAccess from "./EditRoomAccess";
import Test from "./Test";
import { Alert } from "@material-ui/lab";
import AppCoverStudent from "./AppCoverStudent";
import RenderKenken from "./RenderKenken";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { Redirect } from "react-router-dom";
import RenderSudoku from "./RenderSudoku";
const styles = (theme) => ({
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    alignItems: "flex-start",
  },
  leaderdisplay: {
    marginTop: "40px",
    marginLeft: "10px",
  },
  activestudentdisplay: {
    right: "10px",
    top: "64px",
    position: "absolute",
  },
  puzzle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    marginTop: "20px",
    width: "50%",
    alignItems: "center",
    "& .MuiTextField-root": {
      marginLeft: "20px",
      width: "8em",
    },
  },
  formInput: {
    marginBottom: "10px",
    display: "flex",
    // flexWrap: "wrap",
    // alignItems:"center",
    justifyContent: "center",
    // [theme.breakpoints.down('sm')]:{
    //   flexWrap: "nowrap"
    // }
  },
  infoChild: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "10px",
  },
  editRoom: {
    marginLeft: "10px",
  },
  leaderdisplayparent: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  libraryModal: {
    position: "absolute",
    // width: 450,
    // height: "90%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    // border: '2px s',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    maxHeight: "60%",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "space-around",
  },
});

let socket;

class TeacherRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomDetails: this.props.roomDetails,
      activeStudents: 0,
      puzSize: 3,
      disableGenerate: false,
      time: "00:05",
      puzConfig: {},
      ispuzAssigned: false,
      seconds: 0,
      min: "03",
      sec: "00",
      leaderboard: [],
      disableSend: false,
      listActiveStudents: [],
      editRoom: false,
      puzOperations: [MathOperators.ADDITION],
      difficulty: "easy",
      confirm: false,
      message: { open: false, data: "", severity: "" },
      puzzleList: [],
      puzType: "",
      puzListLoading: false,
      puzListModal: false,
      logout: false,
    };
    this.getActiveStudents = this.getActiveStudents.bind(this);
    this.handlePuzSizeChange = this.handlePuzSizeChange.bind(this);
    this.handleminuteChange = this.handleminuteChange.bind(this);
    this.updatePuzConfig = this.updatePuzConfig.bind(this);
    this.sendtoStudents = this.sendtoStudents.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.calculateSeconds = this.calculateSeconds.bind(this);
    this.handlesecondChange = this.handlesecondChange.bind(this);
    this.getLeaderboard = this.getLeaderboard.bind(this);
    this.exportData = this.exportData.bind(this);
    this.onCancelTimer = this.onCancelTimer.bind(this);
    this.editRoom = this.editRoom.bind(this);
    this.stopEditRoom = this.stopEditRoom.bind(this);
    this.handleDifficulty = this.handleDifficulty.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.resetLeaderboard = this.resetLeaderboard.bind(this);
  }

  componentWillUnmount() {
    console.log("teacher Left");
    socket.disconnect();
  }

  componentDidMount() {
    socket = io(base_url);

    console.log(socket);

    socket.on("connect", () => {
      socket.emit("createroom", {
        roomid: this.props.roomDetails.roomid,
        username: this.props.userData.username,
      });
    });

    socket.on("info", (username, roomid) => {
      console.log(username, roomid, "JOINED");
    });

    socket.on("newstudentcount", () => {
      this.getActiveStudents();
    });

    socket.onAny((event, ...args) => {
      console.log(`got ${event}`);
      this.getActiveStudents();
    });
    socket.on("leaderboardupdate", () => {
      console.log("Getting leader board");
      this.getLeaderboard();
    });

    // this.getPuzzlelist();
  }

  getActiveStudents() {
    console.log("requesting new room data");
    let config = {
      headers: {
        authorization: `BearerToken ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(
        `${base_url}listactivestudents?roomid=${this.state.roomDetails.roomid}`,
        config
      )
      .then((data) => {
        // console.log(data)
        this.setState({
          activeStudents: data.data.totalStudents,
          listActiveStudents: data.data.students,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handlePuzSizeChange(e) {
    e.preventDefault();
    console.log(isNaN(e.target.value));
    if (isNaN(e.target.value)) {
      console.log(e.target.value);
      return null;
    } else if (
      (e.target.value > 2 && e.target.value < 9) ||
      e.target.value === ""
    ) {
      this.setState({
        puzSize: e.target.value,
      });
    }
  }

  handleminuteChange(e) {
    e.preventDefault();
    let val = e.target.value;
    if (isNaN(val)) {
      return null;
    }
    if (val.length <= 2) {
      if (val === "") {
        this.setState({
          min: 0,
        });
      } else {
        this.setState({
          min: parseInt(val),
        });
      }
    }
  }

  handlesecondChange(e) {
    e.preventDefault();
    let val = e.target.value;
    if (isNaN(val)) {
      return null;
    }
    if (val.length <= 2) {
      if (val === "") {
        this.setState({
          sec: 0,
        });
      } else {
        this.setState({
          sec: parseInt(val),
        });
      }
    }
  }

  handleTerminate = () => {
    socket.emit("terminatesession", this.state.roomDetails.roomid);
  };

  handleTerminateStudent = (username) => {
    socket.emit("terminatesession", this.state.roomDetails.roomid, username);
  };

  updatePuzConfig(data) {
    console.log("RECEIVED BY PARENT", data);
    this.setState({
      puzConfig: data,
    });
  }

  sendtoStudents(event) {
    event.preventDefault();
    console.log("Send");
    console.log(JSON.stringify(this.state.puzConfig));
    this.setState({
      seconds: this.calculateSeconds(),
    });
    socket.emit(
      "puzzleassign",
      {
        config: this.state.puzConfig,
        puzType: this.state.puzType,
        min: this.state.min,
        sec: this.state.sec,
        roomid: this.state.roomDetails.roomid,
      },
      (data) => {
        console.log(data);
        this.setState({
          ispuzAssigned: true,
        });
        this.startTimer();
      }
    );
  }

  calculateSeconds() {
    let secs = parseInt(this.state.min * 60) + parseInt(this.state.sec);
    return secs;
  }

  startTimer() {
    if (!this.timer && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    let newTime = parseInt(this.state.seconds) - 1;
    if (String(newTime).length) {
      newTime = "0" + newTime;
    }

    this.setState({
      seconds: newTime,
    });
    console.log(newTime);

    if (parseInt(newTime) === 0) {
      clearInterval(this.timer);
      this.timer = 0;
      this.setState({
        ispuzAssigned: false,
      });
    }
  }

  getLeaderboard() {
    console.log("Getting leaderboard");
    let config = {
      headers: {
        authorization: `BearerToken ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(
        `${base_url}leaderboard?roomid=${this.state.roomDetails.roomid}`,
        config
      )
      .then(({ data }) => {
        this.setState({
          leaderboard: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  exportData() {
    let csvData = [
      [
        `RoomId: ${this.state.roomDetails.roomid}`,
        `RoomName: ${this.state.roomDetails.roomName}`,
        `Organizer: ${this.state.roomDetails.createdBy}`,
      ],
      [
        `Rank`,
        `StudentName`,
        `email`,
        `ID No.`,
        `School`,
        `Total Score`,
        `Accumulated Time Spent (seconds)`,
      ],
    ];
    this.state.leaderboard.map((val, index) => {
      csvData[index + 2] = [
        index + 1,
        val.name,
        val.email,
        val.id,
        val.school,
        val.score,
        val.accumulatedTime,
      ];
    });

    this.setState({
      csvExportData: csvData,
      download: true,
    });
    // this.setState({download: false})
  }

  onCancelTimer() {
    clearInterval(this.timer);
    this.timer = 0;
    this.setState({
      ispuzAssigned: false,
      seconds: 0,
    });
    socket.emit("requestsubmit", this.state.roomDetails.roomid);
  }

  editRoom() {
    this.setState({
      editRoom: true,
      confirm: false,
    });
  }
  stopEditRoom() {
    this.setState({
      editRoom: false,
    });
  }

  handleDifficulty(e) {
    e.preventDefault();
    this.setState(
      {
        difficulty: e.target.value,
      },
      () => {
        let operation = [MathOperators.ADDITION];
        if (this.state.difficulty === "medium") {
          operation = [MathOperators.ADDITION, MathOperators.SUBTRACTION];
        }
        if (this.state.difficulty === "difficult") {
          operation = [
            MathOperators.ADDITION,
            MathOperators.SUBTRACTION,
            MathOperators.MULTIPLICATION,
            MathOperators.DIVISION,
          ];
        }

        this.setState({
          puzOperations: operation,
        });
      }
    );
  }

  handleConfirm(condition) {
    this.setState({
      confirm: condition,
    });
  }

  resetLeaderboard(e) {
    e.preventDefault();
    let roomid = this.state.roomDetails.roomid;
    axios
      .post(`${base_url}resetleader`, {
        roomid: roomid,
      })
      .then(({ data }) => {
        console.log("LeaderReset");
        this.getLeaderboard();
        socket.emit("resetleader", roomid);
        this.setState({
          message: {
            open: true,
            data: "LeaderBoard Reset Success",
            severity: "success",
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          message: {
            open: true,
            data: "LeaderBoard Reset Failed! Try Again.",
            severity: "error",
          },
        });
      });
  }

  getPuzzlelist = () => {
    this.setState({
      puzListLoading: true,
    });
    axios
      .get(`${base_url}getpuzzlelist?teacherid=${this.props.userData.username}`)
      .then(({ data }) => {
        this.setState({
          puzzleList: data,
          puzListLoading: false,
        });
        console.log(data);
        // console.log(this.state);
      })
      .catch((err) => {
        this.setState({
          puzListLoading: false,
        });
      });
  };

  handleModalOpen = () => {
    this.getPuzzlelist();
    this.setState({
      puzListModal: true,
      // puzListLoading: true
    });
  };

  handlePuzzleSelect = (data) => {
    this.setState({
      puzConfig: data.puzzleConfig,
      puzType: data.puzType,
      confirm: true,
      puzListModal: false,
    });
  };

  render() {
    const { classes } = this.props;
    console.log(this.state.puzSize);
    // console.log(classes);
    return (
      <Box>
        {this.state.logout && <Redirect to="/login" />}

        <Snackbar
          open={this.state.message.open}
          autoHideDuration={2000}
          onClose={() => {
            this.setState({ message: { open: false, data: "", severity: "" } });
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={this.state.message.severity}>
            {this.state.message.data}
          </Alert>
        </Snackbar>

        <Grid container className={classes.infoContainer} spacing={2}>
          <Grid xs item className={classes.infoChild}>
            {!this.state.editRoom ? (
              <Button startIcon={<EditIcon />} onClick={this.editRoom}>
                Edit Room Access
              </Button>
            ) : (
              <Button
                startIcon={<ArrowBackIcon />}
                onClick={this.stopEditRoom}
              ></Button>
            )}
          </Grid>
          <Grid xs item className={classes.infoChild}>
            <Typography
              variant="body1"
              style={{ fontWeight: "600" }}
              align="center"
            >
              Room Id:{" "}
            </Typography>
            <Typography
              variant="body2"
              style={{ margin: "auto 10px", fontSize: "1em" }}
            >
              {this.state.roomDetails.roomid}
            </Typography>
          </Grid>
          <Grid xs item className={classes.infoChild}>
            <Typography
              variant="body1"
              style={{ fontWeight: "600" }}
              align="center"
            >
              Room Name:{" "}
            </Typography>
            <Typography variant="body2" style={{ margin: "auto 10px" }}>
              {this.state.roomDetails.roomName}
            </Typography>
          </Grid>

          {!this.state.editRoom && (
            <>
              <Grid xs item className={classes.infoChild}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: "600" }}
                  align="center"
                >
                  Total Active Students:{" "}
                </Typography>
                <Typography variant="body2" style={{ margin: "auto 10px" }}>
                  {this.state.activeStudents}
                </Typography>
              </Grid>
              <Grid xs item className={classes.infoChild}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleTerminate();
                  }}
                  variant="text"
                  startIcon={<CancelPresentationIcon style={{ fill: "red" }} />}
                >
                  End Session
                </Button>
              </Grid>
            </>
          )}

          {this.state.leaderboard.length !== 0 && !this.state.editRoom ? (
            <Grid xs item className={classes.infoChild}>
              <Typography
                variant="body1"
                style={{ fontWeight: "600" }}
                align="center"
              >
                Export Room Data:{" "}
              </Typography>
              <Button
                style={{ margin: "auto" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.exportData();
                }}
              >
                Create Export
              </Button>
              <Box
                component="div"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {this.state.download && (
                  <CSVLink
                    data={this.state.csvExportData}
                    filename={`${
                      this.state.roomDetails.roomid
                    }-${Date.now()}.csv`}
                    className={classes.link}
                    target="_blank"
                    onClick={(event, done) => {
                      this.setState({ download: false });
                    }}
                  >
                    Download data
                  </CSVLink>
                )}
              </Box>
            </Grid>
          ) : null}
        </Grid>
        {this.state.editRoom && (
          <EditRoomAccess
            roomdata={this.state.roomDetails}
            className={classes.editRoom}
            terminateStudent = {this.handleTerminateStudent}
          />
        )}
        {!this.state.editRoom && (
          <Box>
            <Box className={classes.leaderdisplayparent}>
              <LeaderBoard
                className={classes.leaderdisplay}
                data={this.state.leaderboard}
              />
              <Button onClick={this.resetLeaderboard}>Reset LeaderBoard</Button>
            </Box>
            <LeaderBoard
              className={classes.activestudentdisplay}
              data={this.state.listActiveStudents}
              type="activestudents"
            />
            <Box className={classes.puzzle}>
              <Box className={classes.puzzle}>
                {this.state.ispuzAssigned && (
                  <Box style={{ display: "flex" }}>
                    <Timer time={this.state.seconds} />
                    <Tooltip title="Force Stop the timer">
                      <Button
                        onClick={this.onCancelTimer}
                        startIcon={<HighlightOffIcon />}
                        style={{ padding: "0" }}
                      />
                    </Tooltip>
                  </Box>
                )}
                {this.state.seconds == 0 ? (
                  <Box className={classes.formInput}>
                    {/* <TextField
                      id="standard-basic"
                      label="Size(3-8)"
                      type="number"
                      InputProps={{
                        inputProps: { min: 1, max: 8 },
                        style: { textAlign: "center" },
                      }}
                      value={this.state.puzSize}
                      onChange={this.handlePuzSizeChange}
                    /> */}
                    <TextField
                      id="time"
                      label="Minutes"
                      // inputProps={{type:"time"}}
                      value={this.state.min}
                      onChange={this.handleminuteChange}
                      style={{ width: "2.5rem" }}
                      inputProps={{ style: { textAlign: "center" } }}
                    />
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      :
                    </span>
                    <TextField
                      id="time"
                      label="Seconds"
                      // inputProps={{type:"time"}}
                      value={this.state.sec}
                      onChange={this.handlesecondChange}
                      style={{ width: "2.5rem" }}
                      inputProps={{ style: { textAlign: "center" } }}
                    />
                    {/* <FormControl style={{width:"50%", marginTop:"5px"}}>
                <Select 
                label="Difficulty" value={this.state.difficulty} onChange={this.handleDifficulty}>
                  <MenuItem value="easy">
                    Easy  
                  </MenuItem>
                  <MenuItem value="medium">
                    Medium
                  </MenuItem>
                  <MenuItem value="difficult">
                    Difficult
                  </MenuItem>
                </Select>
                  <FormHelperText>Difficulty</FormHelperText>
                </FormControl> */}
                  </Box>
                ) : null}
                {/* <AppCover
                size={this.state.puzSize}
                disableGenerate={this.state.puzSize}
                role="teacher"
                updatePuz={this.updatePuzConfig}
                operations = {this.state.puzOperations}
              /> */}
                {Object.keys(this.state.puzConfig).length !== 0 &&
                this.state.puzType == "Kenken" ? (
                  // <Test
                  //   size={this.state.puzSize}
                  //   toggleConfirm={this.handleConfirm}
                  //   updatePuzConfig={this.updatePuzConfig}
                  // />
                  <RenderKenken
                    settings={this.state.puzConfig}
                    solve={true}
                    checksolution={false}
                    // handleinputchange={this.handleinputchange}
                    // input={this.state.input}
                    role="teacher"
                  />
                ) : null}
                {this.state.puzType == "Sudoku" && (
                  <RenderSudoku
                    values={this.state.puzConfig.values}
                    correctSolution={this.state.puzConfig.solution}
                    view
                    teacher
                  />
                )}
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleModalOpen();
                  }}
                >
                  Choose from Puzzle library
                </Button>
                <Tooltip
                  title={
                    this.state.ispuzAssigned
                      ? "Please wait untill the students complete the given Puzzle"
                      : "Assign this puzzle to Students"
                  }
                >
                  <span>
                    <Button
                      endIcon={<SendIcon />}
                      onClick={(event) => {
                        this.sendtoStudents(event);
                      }}
                      disabled={
                        this.state.ispuzAssigned ||
                        this.state.min + this.state.sec === 0 ||
                        !this.state.confirm
                      }
                    >
                      Send
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        )}

        <Modal
          open={this.state.puzListModal}
          onClose={() => {
            this.setState({ puzListModal: false });
          }}
        >
          <Paper className={classes.libraryModal}>
            <Typography variant="h6">Your Library</Typography>
            {this.state.puzListLoading && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!this.state.puzListLoading && (
              <List style={{ alignItems: "center" }}>
                {this.state.puzzleList.map((data) => (
                  <ListItem
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      this.handlePuzzleSelect(data);
                    }}
                    key={data._id}
                  >
                    {data.puzType == "Kenken"
                      ? data.puzzleName +
                        " " +
                        "(" +
                        data.puzzleConfig.size +
                        "X" +
                        data.puzzleConfig.size +
                        ")"
                      : data.puzzleName + " (Sudoku)"}
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </Modal>
      </Box>
    );
  }
}

export default withStyles(styles)(TeacherRoom);
