import { roundFloat } from "kengen";
import React, { Component } from "react";
// import "./RenderKenken.module.css";


class Cell extends React.Component {
  // constructor(props){
  //   super(props)
  //   this.score = this.score.bind(this)
  // }
  render() {
    let mypos = this.props.pos;
    let cellGroup = this.props.map;
    let size = this.props.size;
    let role = this.props.role;
    let adj_pos = {
      top: mypos - parseInt(size),
      left: mypos - 1,
      right: mypos + 1,
      bottom: mypos + parseInt(size),
    };

    let wd = "4em"
    let ht = "4em"
    if(size > 5){
      wd = "50px"
      ht = "50px"
    }
    if(size > 8){
      wd = "40px"
      ht = "40px"
    }

    if(role==='teacher'){
      // if(size>5){
      //   wd = "2.5em"
      //   ht = "2.5em"
      // }
    }
    
    // console.log(adj_pos)
    let cellStyle = {
      width: wd,
      height: ht,
      textAlign: "center",
      borderTop: "1px solid gray",
      borderBottom: "1px solid gray",
      borderLeft: "1px solid gray",
      borderRight: "1px solid gray",
      position: "relative",
      backgroundColor:"white",
    };
    let text = "";
    // console.log(this.props.solve)
    // console.log("Input", this.props.input[mypos], "Ans ", this.props.solution, "ASd")
    cellGroup.forEach((group) => {
      const cells = new Set(group.cells);
      // console.log("CELLS", group.cells.length)
      let cellGroupLength = group.cells.length
      const operator = group.operation;
      const val = group.result;
      if (mypos === cells.values().next().value) {
        text = operator
        if(cellGroupLength == 1){
          text = " ";
        }
        text = text + " " + roundFloat(val);
      }

      if (cells.has(mypos)) {
        if (!cells.has(adj_pos.top)) {
          cellStyle.borderTop = "3px solid black";
        }
        if (!cells.has(adj_pos.bottom)) {
          cellStyle.borderBottom = "3px solid black";
        }
        if (!cells.has(adj_pos.left)) {
          cellStyle.borderLeft = "3px solid black";
        }
        if (!cells.has(adj_pos.right)) {
          cellStyle.borderRight = "3px solid black";
        }
        if (mypos % size === 0) {
          cellStyle.borderLeft = "3px solid black";
        }
        if ((mypos + 1) % size === 0) {
          cellStyle.borderRight = "3px solid black";
        }
      }
    });

    if(this.props.check && !this.props.solve){
      let backcolor = "red";
      if(this.props.input[mypos] == this.props.solution){
        backcolor = "green";
        // this.props.score(true)
      }
      // else{
      //   // this.props.score(false)
      // }
      cellStyle.backgroundColor = backcolor
    }
    // console.log("SOLUTION", this.props.solution == this.props.input[mypos])
    return (
      <td className={"cell" + mypos} style={cellStyle}>
        <div
          style={{ position: "absolute", top: "0", left: "0", fontSize: "0.7em" }}
        >
          {text}
        </div>
        { !this.props.solve && !this.props.check && (this.props.role === "student") &&
        // <input
        //   style={{
        //     height: "95%",
        //     width: "95%",
        //     border: "0",
        //     textAlign: "center",
        //     fontSize: "1.3em",
        //     fontWeight: "bold",
        //   }}
        //   value={this.props.input[mypos]}
        //   onChange={(event) => this.props.inputcallback(mypos, event)}
        // />
        <div style={{
          width:"100%",
          height:"100%",
          textAlign: "center",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          fontSize: "1.3em",
          fontWeight: "bold",
          position:"relative",
          zIndex:"10",
          border: this.props.activeCell === mypos && !this.props.hint  ? "1px solid blue" : (this.props.activeCell === mypos && this.props.hint ? "2px dashed orange" : "")
        }} 

        onClick={(e) => {e.preventDefault(); this.props.handleActiveCell(mypos)}}
        >
          {this.props.input[mypos]}
        </div>
        }
        {
          !this.props.solve && !this.props.check && (this.props.role === "student") &&
          <div style={{
            width:"100%",
            height:"100%",
            position:"absolute", 
            top:"0", 
            left:"0", 
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
          }}>
            {!text ?
              <div style={{
                width:"28px",
                height:"28px",
                marginRight:"auto",
                marginLeft:"auto",
                marginTop:"auto",
                marginBottom:"auto",
                position:"relative"
              }}>
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 1).display && <div style={{position: "absolute", top:"0px", left:"0", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>1</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 2).display &&<div style={{position: "absolute", top:"0", left:"10px", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>2</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 3).display &&<div style={{position: "absolute", top:"0", left:"20px", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>3</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 4).display &&<div style={{position: "absolute", top:"10px", left:"0", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>4</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 5).display && <div style={{position: "absolute", top:"10px", left:"10px", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>5</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 6).display && <div style={{position: "absolute", top:"10px", left:"20px", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>6</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 7).display && <div style={{position: "absolute", top:"20px", left:"0", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>7</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 8).display &&<div style={{position: "absolute", top:"20px", left:"10px", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>8</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 9).display && <div style={{position: "absolute", top:"20px", left:"20px", width:"9px", height:"9px", fontSize:"8px", lineHeight:"10px"}}>9</div>  }
              </div>
            
              :

              <div style={{
                width:"32px",
                height:"32px",
                marginRight:"auto",
                marginLeft:"auto",
                marginTop:"auto",
                marginBottom:"auto",
                position:"relative"
              }}>
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 1).display && <div style={{position: "absolute", top:"0px", left:"20px", width:"8px", height:"8px", fontSize:"8px", lineHeight:"10px"}}>1</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 2).display && <div style={{position: "absolute", top:"10px", left:"0px", width:"8px", height:"8px", fontSize:"8px", lineHeight:"10px"}}>2</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 3).display && <div style={{position: "absolute", top:"10px", left:"8px", width:"8px", height:"8px", fontSize:"8px", lineHeight:"10px"}}>3</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 4).display && <div style={{position: "absolute", top:"10px", left:"15px", width:"8px", height:"8px", fontSize:"8px", lineHeight:"10px"}}>4</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 5).display &&<div style={{position: "absolute", top:"10px", left:"22px", width:"8px", height:"8px", fontSize:"8px", lineHeight:"10px"}}>5</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 6).display && <div style={{position: "absolute", top:"20px", left:"0px", width:"8px", height:"8px", fontSize:"8px", lineHeight:"10px"}}>6</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 7).display && <div style={{position: "absolute", top:"20px", left:"8px", width:"8px", height:"8px", fontSize:"8px", lineHeight:"10px"}}>7</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 8).display && <div style={{position: "absolute", top:"20px", left:"15px", width:"8px", height:"8px", fontSize:"8px", lineHeight:"10px"}}>8</div>}
                {this.props.hintVal.find((el) => el.pos == mypos && el.pos_i == 9).display && <div style={{position: "absolute", top:"20px", left:"22px", width:"8px", height:"8px", fontSize:"8px", lineHeight:"10px"}}>9</div>  }
              </div>

             }

          </div>
        }
        {
            this.props.solve &&
            <span style={{fontSize:"1em", fontWeight:"bold"}}>
                {this.props.solution}
            </span>            
        }
        {
          this.props.check && !this.props.solve  &&
          <span 
          style={{
            fontSize:"1.3em",
            fontWeight:"bold",
            }}>
                {this.props.input[mypos]}
            </span>
        }
      </td>
    );
  }
}



//PARENT COMPONENT
export default class RenderKenken extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   solve: this.props.solve,
    //   settings: this.props.settings
    // }
  }

  // componentWillReceiveProps(){
  //   this.setState({
  //     solve: this.props.solve,
  //     settings: this.props.settings
  //   })
  // }  
 

  render() 
    
  {
    

    // console.log(this.state);
    // console.log("PROPS",this.props)
    const size = this.props.settings.size;
    let map_arr = this.props.settings.math;
    let map = new Set(map_arr)

    
    // console.log(map)
        // const values = this.state.cells
    let rows = [];
    for (let y = 0; y < size; y++) {
      // Build the cells in an array
      const cells = [];
      for (let x = 0; x < size; x++) {
        let pos = y * size + x;
        let solution = this.props.settings.cells[pos];
        cells.push(
          <Cell
            key={x}
            pos={pos}
            map={map}
            size={size}
            input={this.props.input}
            inputcallback={this.props.handleinputchange}
            solve={this.props.solve}
            solution={solution}
            check={this.props.checksolution}
            score = {this.props.handlescore}
            role={this.props.role}
            handleActiveCell = {this.props.handleActiveCell}
            activeCell={this.props.activeCell}
            hint={this.props.hint}
            hintVal={this.props.hintVal}
          />
        );
      }
      // Put them in the row
      rows.push(<tr key={y}>{cells}</tr>);
    }
    // Return the table
    return (
      <table cellPadding={0} cellSpacing={0} style={{alignSelf:'center'}}>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}
