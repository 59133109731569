import { React, useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { Redirect } from "react-router";
import { base_url } from "../utils/variables";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        Pengang Math Platform
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#8300E9",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Signup(props) {
  const classes = useStyles();
  // let studentInfo = props.studentInfo
  let [edit, setEdit] = useState(props.edit)
  let [editInfo, setEditInfo] = useState(props.studentInfo)
  let [name, setName] = useState((edit) ? editInfo.name : "");
  let [id, setId] = useState((edit) ? editInfo.id : "");
  let [school, setSchool] = useState((edit) ? editInfo.school : "");
  let [email, setEmail] = useState((edit) ? editInfo.email : "");
  let [contact, setContact] = useState((edit) ? editInfo.contact : "");
  let [parentName, setParentName] = useState((edit) ? editInfo.parentName : "");
  let [parentContact, setParentContact] = useState((edit) ? editInfo.parentContact : "");
  let [registerSuccess, setRegisterSuccess] = useState(false);
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [usernameError, setUsernameError] = useState(false);
  let [submitDisale, setSubmitDisable] = useState(true);
  let [oldUsername, setOldUsername] = useState((edit) ? editInfo.username : "")
  // if(edit){
  // }
  const handleNameChange = (e) => {
    setName(e.target.value);
    checkSubmitDisable();
  };

  const handleRegister = () => {
      let data = {
        name: name,
        id: id,
        username: id,
        password: id,
        school: school,
        email: email,
        contact: contact,
        parentName: parentName,
        parentContact: parentContact,
        oldUsername: oldUsername,
        role:"student"
      }
      if(edit){
        axios.post(`${base_url}edituser`, data)
        .then(({data}) => {
          // console.log(data)
          props.handleRegister(data)
          if(data.error){
            setUsernameError(true)
          }
          console.log("Registration success")
        })
        .catch(err => {
          console.log(err)
        })
      }
      else{
        axios.post(`${base_url}register`, data)
        .then(({data}) => {
          // console.log(data)
          props.handleRegister(data)
          if(data.error){
            setUsernameError(true)
          }
          console.log("Registration success")
        })
        .catch(err => {
          console.log(err)
        })
      }
  };

  const handleIdChange = (e) => {
    setId(e.target.value);
    checkSubmitDisable();
  };

  const handleSchoolChange = (e) => {
      setSchool(e.target.value);
      checkSubmitDisable()
  };

  const handleusernameChange = (e) => {
    setUsername(e.target.value)
    checkSubmitDisable()
  }
  const checkSubmitDisable = () => {
    if(!name || !id || !school || !email || !contact || !parentName || !parentContact ){
      setSubmitDisable(true)
    }
    else{
      setSubmitDisable(false)
    }
  }

  useEffect(() => {
    checkSubmitDisable()
  })
 
  return (
    <Container component="main" maxWidth="xs">
      {/* <CssBaseline /> */}
      {/* {registerSuccess && <Redirect to={{ pathname: "/dashboard" }} />} */}

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {(!edit) ? "Register" : "Editing Student"}
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={name}
            onChange={(e) => handleNameChange(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="id"
            label="Mykad Id / Passport Number"
            name="id"
            autoFocus
            value={id}
            onChange={(e) => handleIdChange(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="school"
            label="School Name"
            name="school"
            autoFocus
            value={school}
            onChange={(e) => handleSchoolChange(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email-Id"
            name="email"
            autoFocus
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              checkSubmitDisable()
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="contact"
            label="Student Contact"
            name="contact"
            autoFocus
            value={contact}
            onChange={(e) => {
              if (!isNaN(e.target.value)) {
                setContact(e.target.value);
                checkSubmitDisable()
              }
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="pname"
            label="Parent/Guardian Name"
            name="pname"
            autoFocus
            value={parentName}
            onChange={(e) => {
              setParentName(e.target.value)
              checkSubmitDisable()
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="pcontact"
            label="Parent Contact"
            name="pcontact"
            autoFocus
            value={parentContact}
            onChange={(e) => {
              if (!isNaN(e.target.value)) {
                setParentContact(e.target.value);
                checkSubmitDisable()
              }
            }}
          />
          <Divider />
          <br/>
          <Typography variant="body2">* The National Id/ Passport Number will be used as username and password<br></br>(these details will be used for login)</Typography>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleRegister}
            disabled={submitDisale}
          >
            Confirm
          </Button>
        </form>
      </div>
    </Container>
  );
}
